import axios from '../utils/axios'

export function traininglist(params) {//列表
  return axios.post('/TrainingRegistration/traininglist', params);
}
export function detail(params) {//详情
  return axios.get('/TrainingRegistration/detail'+params);
}
export function tr_submit(params) {//获取城市
  return axios.post('/TrainingRegistration/tr_submit', params);
}
