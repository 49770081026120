<template>
    <div class="archive">
        <ArchvieHeader />
        <div class="subject">
            <van-image :src="require('@/assets/images/icon-archive-switch.png')" />
            <div class="title">阶段切换</div>
        </div>
        <div class="archive-body">
            <div class="archive-item" @click="$router.push('/archive/list?stage=1')">
                <span>{{user.name||'**'}}的小学成长手册</span>
                <van-button color="#E9A105" size="small" plain>编辑</van-button>
            </div>
            <div class="archive-item" @click="$router.push('/archive/list?stage=2')">
                <span>{{user.name||'**'}}的初中成长手册</span>
                <van-button color="#E9A105" size="small" plain>编辑</van-button>
            </div>
            <div class="archive-item" @click="$router.push('/archive/list?stage=3')">
                <span>{{user.name||'**'}}的高中成长手册</span>
                <van-button color="#E9A105" size="small" plain >编辑</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import ArchvieHeader from '@/views/layouts/ArchiveHeader'
import { Button, Icon,Toast, Image } from 'vant'
import { reactive } from '@vue/reactivity'
import { userinfo } from '@/service/user'
import { useRouter } from 'vue-router'
export default {
    components: {
        ArchvieHeader,
        [Button.name]: Button,
        [Icon.name]: Icon,
        [Image.name]: Image
    },
    setup() {
		const router = useRouter()
		const user = reactive({
		    name: null,
		})
		onMounted(async () => {
		  var userinfos  = await userinfo();
		  user.name = userinfos.data.user.nickname
		  if(!user.name) {
			  Toast('请先完善基本信息！')
			  router.push('/profile')
		  }
		})
        return {
			user
		}
    }
}
</script>

<style lang="scss" scoped>
.subject {
    display: flex;
    align-items: center;
    padding: 30px 40px;
    background-color: #fff;

    .van-image {
        width: 40px;
    }

    .title {
        margin: 0;
        margin-left: 18px;
        color: #4a4a4a;
        font-size: 30px;
    }
}

.archive-body {
    min-height: calc(100vh - 917px);
    padding: 40px;
    background-color: #c8dbfb;

    .archive-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        margin-bottom: 10px;
        padding: 0 40px;
        background-color: #fff;
        box-sizing: border-box;
    }
}
</style>