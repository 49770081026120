<template>

	<div class="search-box">
		<input type="text" placeholder="查找比赛" v-model="state.keyword" />
		<button class="btn-search" @click="search()">
			<img src="@/assets/images/icon-search.png" />
		</button>
	</div>
	<div class="banner" v-if="state.images.length">
		<van-swipe class="my-swipe" :autoplay="3000" height="140">
			<van-swipe-item v-for="image in state.images" :key="image.id" @click="href(image.url)">
				<img class="my-swipe-img" :src="image.img" />
			</van-swipe-item>
		</van-swipe>
	</div>

	<div class="operations block">

		<van-grid :column-num="4" :border="false">
			<van-grid-item v-for="value in operations" :key="value.alias"
				:icon="require(`@/assets/images/icon-${value.alias}.png`)" :text="value.name"
				@click="jump(value.alias)" />
			<div style="flex-basis: 25%;position: relative">
				<wx-open-launch-weapp id="launch-btn" :username="state.xapp.username" :path="state.xapp.path"
					style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10000;">
					<div v-is="'script'" type="text/wxtag-template">
						<div style="width: 100%; height: 76px; opacity:0;">跳转小程序</div>
					</div>
				</wx-open-launch-weapp>
				<van-grid-item key="shop" :icon="require(`@/assets/images/icon-shop.png`)" text="科学好物" />
			</div>

		</van-grid>
	</div>

	<div class="block">
		<div class="block-header">
			<div class="subject">
				<van-image :src="require('@/assets/images/jys.png')" />
				<div class="title">江苏省中小学金钥匙科技竞赛查询系统</div>
			</div>
		</div>
		<van-tabs v-model:active="state.tabActive" color="#4a90e2" title-active-color="#4a90e2">
			<!-- <van-tab title="成绩查询" title-style="font-weight: bold;flex: none;font-size:14px;">
				<div class="hx"></div>
				<div class="verinfo">
					<div v-if="state.cjcz==0">
						<van-form @submit="onSubmit1" class="forms">
							<van-field v-model="state.username" name="姓名" label="姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名"
								placeholder="请填写姓名" :rules="[{ required: true, message: '请填写姓名' }]"
								label-width="60px" />
							<van-field v-model="state.school" name="学校全称" label="学校全称" placeholder="请填写学校全称"
								:rules="[{ required: true, message: '请填写学校全称' }]" label-width="60px" />
							<div style="margin: 36px;">
								<van-button round block type="primary" size="small" native-type="submit">
									提交
								</van-button>
							</div>
					 </van-form>
					</div>
					<div align="center" v-else>
						<div class="title">您的成绩：</div>
						<div class="cjzhi">{{state.cjdata.score}}分</div>
						<div class="fh" @click="state.cjcz=0">
							<van-icon name="arrow-left" />返回
						</div>
					</div>

				</div>
			</van-tab> -->
			<van-tab title="证书核验" title-style="font-weight: bold;flex: none;margin-left:20px;">
				<div class="hx"></div>
				<div class="verinfo">
					<div v-if="state.zshy==0">
						<van-form @submit="onSubmit2" class="forms">
							<van-field v-model="state.num" name="证书号" label="证书号" placeholder="请输入含JYS开头的十位编码"
								:rules="[{ required: true, message: '请输入含JYS开头的十位编码' }]" label-width="60px" />
							<!-- <van-field v-model="state.username" name="姓名" label="姓&nbsp;&nbsp;&nbsp;&nbsp;名"
								placeholder="请填写姓名" :rules="[{ required: true, message: '请填写姓名' }]"
								label-width="60px" /> -->
							<div style="margin: 36px;">
								<van-button round block type="primary" size="small" native-type="submit">
									提交
								</van-button>
							</div>
						</van-form>
					</div>
					<div align="center" v-else>
						<div class="title width200">
							<span v-if="state.zshydata.lao_type=='101'">金钥匙科技竞赛个人赛</span>
							<span v-if="state.zshydata.lao_type=='102'">金钥匙科技竞赛团体赛</span>
							<span v-if="state.zshydata.lao_type=='200'">校园之间科技竞技活动</span>
							<span v-if="state.zshydata.lao_type=='301'">青少年人工智能大赛线上赛</span>
							<span v-if="state.zshydata.lao_type=='302'">青少年人工智能大赛线下赛（智能机器人）</span>
							<span v-if="state.zshydata.lao_type=='303'">青少年人工智能大赛线下赛（仿生机器人）</span>
							<span v-if="state.zshydata.lao_type=='304'">青少年人工智能大赛线下赛（无人机）</span>
							<span v-if="state.zshydata.lao_type=='305'">青少年人工智能大赛线下赛（陆地机器人）</span>
							<span v-if="state.zshydata.lao_type=='306'">青少年人工智能大赛线下赛（中韩青少年机器人）</span>
							<span v-if="state.zshydata.lao_type=='307'">青少年人工智能大赛线上赛（仿生机器人）</span>
							<span v-if="state.zshydata.lao_type=='400'">初中CESL活动</span>
							<span v-if="state.zshydata.lao_type=='500'">金钥匙儿童科学创想画活动（小学低年级）</span>
							<span v-if="state.zshydata.lao_type=='600'">苏台青少年科技交流活动</span>
							<span v-if="state.zshydata.lao_type=='700'">文明礼仪测试</span>
							</div>
						<div class="award">{{state.zshydata.awards_name}}</div>
						<div class="cxnames">{{state.zshydata.name}}</div>
						<div class="title">{{state.zshydata.school}}</div>
						<div class="cxnumber">NO.{{state.zshydata.certificate_varnumber}}</div>
						<div class="fh" @click="state.zshy=0">
							<van-icon name="arrow-left" />返回
						</div>
					</div>

				</div>
			</van-tab>
		</van-tabs>
	</div>

</template>

<script>
	import {
		onMounted
	} from 'vue'
	import {
		getHome,
		getWx
	} from '@/service/home'
	import {
		certificate_interface
	} from '@/service/verification'
	import {
		Grid,
		GridItem,
		Image,
		Swipe,
		SwipeItem,
		Toast,
		Tabs,
		Tab,
		Form,
		Field,
		Button,
		Icon,
	} from 'vant'
	import {
		reactive
	} from '@vue/reactivity'
	import {
		useRouter
	} from 'vue-router'
	import wx from 'weixin-js-sdk';

	export default {
		components: {
			[Image.name]: Image,
			[Grid.name]: Grid,
			[GridItem.name]: GridItem,
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			[Toast.name]: Toast,
			[Tabs.name]: Tabs,
			[Tab.name]: Tab,
			[Form.name]: Form,
			[Field.name]: Field,
			[Button.name]: Button,
			[Icon.name]: Icon,
		},
		setup() {
			const operations = reactive([{
					name: '竞赛雷达',
					alias: 'radar'
				},
				{
					name: '成长档案',
					alias: 'archive'
				},
				{
					name: '学业规划',
					alias: 'plan'
				},
				{
					name: '综合测试',
					alias: 'test'
				},
				{
					name: '科普研学',
					alias: 'science'
				},
				{
					name: '培训报名',
					alias: 'training'
				},
				{
					name: '我的账户',
					alias: 'member'
				}
			])
			const state = reactive({
				images: [],
				keyword: '',
				xapp: [],
				tabActive: 0,
				cjcz:0,
				zshy:0,
				username:'',
				school:'',
				num:'',
				cjdata:{},
				zshydata:{}
			})
			const router = useRouter()

			onMounted(async () => {
				const {
					data
				} = await getHome()
				state.images = data.carousel;
				state.xapp = data.xapp;
				wxconfig();
			})

			function jump(alias) {
				if (alias === 'shop') {
					//return
				}

				router.push(alias)
			}
			async function onSubmit1(values) {
				if(!state.username || !state.school) {
					Toast('姓名学校必须填写')
					return;
				}
				 const {
				 	data
				 } = await certificate_interface({
					name: state.username,
					school: state.school,
					type: 2
				})
				state.cjcz = 1
				state.cjdata = data
			}
			async function onSubmit2(values) {
				// if(!state.username || !state.num) {
				// 	Toast('姓名证书号必须填写必须填写')
				// 	return;
				// }
				if(!state.num) {
					Toast('证书号必须填写')
					return;
				}
				
				const {
					data
				} = await certificate_interface({
					name: state.username,
					num: state.num
				})
				state.zshy = 1
				state.zshydata = data
				
			}

			function search(val) {

				router.push('radar?keyword=' + state.keyword)
			}

			function href(url) {
				if (url) {
					window.location.href = url
				}
			}
			async function wxconfig() {
				const {
					data
				} = await getWx({
					url: location.href.split('#')[0]
				})
				wx.config({
					debug: false,
					appId: 'wx09a9523f07e1d16a',
					timestamp: data.timestamp,
					nonceStr: data.nonceStr,
					signature: data.signature,
					jsApiList: ['wx-open-launch-weapp'],
					openTagList: ['wx-open-launch-weapp']
				});

				wx.ready(function() {
					//自动执行的

					//alert('成功');
				});

				wx.error(function(res) {
					//alert(res.errMsg);
				});
			}

			return {
				state,
				operations,
				jump,
				href,
				search,
				wxconfig,
				onSubmit1,
				onSubmit2
			}
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		height: 280px;

		.my-swipe .van-swipe-item {
			color: #fff;
			font-size: 20px;
			line-height: 280px;
			height: 280px;
			text-align: center;
		}

		.my-swipe .my-swipe-img {
			width: 100%;
			height: 280px;
		}
	}

	.block {
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 20px 40px;
		background-color: #fff;

		.block-header {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 32px 0;

			.subject {
				display: flex;
				align-items: center;

				.van-image {
					width: 40px;
				}

				.title {
					margin: 0;
					margin-left: 18px;
					color: #4a4a4a;
					font-size: 28px;
					font-weight: bold;
				}
			}
		}
		.hx {
			border-top: 1px solid #dcdbdb;
		}

		.verinfo {
			height: 550px;
			padding-top: 60px;

			.forms {
				width: 570px;
				margin: 0 auto;
			}
			.width200 {
				width:400px;
			}

			.award {
				display: inline-block;
				text-align: center;
				font-size: 40px;
				margin: 60px 0px 30px;
				    padding: 10px 15px 10px 15px;
				    color: #4A90E2;
				    border-top: 1px solid #4A90E2;
				    border-bottom: 1px solid #4A90E2;
			}
			.cxnames {
				font-size: 40px;
				font-weight: bold;
				margin-bottom: 80px;
			}
			.cxnumber {
				margin: 60px 0 60px;
				font-size: 20px;
			}

			.cjzhi {
				color: #4FDF01;
				font-size: 38px;
				margin: 70px 0 120px;
			}

			.fh {
				color: #0C72BE;
			}
		}

	}
</style>
