<template>
    <div class="profile">
        <div class="header">
            <div class="header-inner">
                <div class="left" @click="$router.back(-1)">
                    <van-icon name="arrow-left" />
                </div>
                <div class="title">科学少年社</div>
                <div class="right"></div>
            </div>
        </div>
        <div class="auth-box">
            <div class="tabs">
                <div class="tab active">完善信息</div>
            </div>
            <div class="auth">
                <van-form @submit="update">
                    <h5 style="padding-left: 16px">基本情况</h5>
                    <van-field
                        v-model="user.name"
                        name="name"
                        label="学生姓名"
                        label-width="70"
                        placeholder="学生姓名"
                    />
                    <van-field name="gender" label-width="70" label="性别">
                        <template #input>
                            <van-radio-group v-model="user.gender" direction="horizontal">
                                <van-radio name="1" checked-color="#E26EFA">男</van-radio>
                                <van-radio name="2" checked-color="#E26EFA">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                        v-model="user.birthday"
                        is-link
                        readonly
                        name="birthday"
                        label="出生日期"
                        label-width="70"
                        placeholder="点击选择时间"
                        @click="showPicker = true"
                    />
                    <van-field
                        v-model="user.areas"
                        is-link
                        readonly
                        name="areas"
                        label="城市"
                        label-width="70"
                        placeholder="点击选择省市区"
                        @click="showArea = true"
                    />
					<van-field
					    v-model="user.grade"
					    is-link
					    readonly
					    name="grade"
					    label="教育等级"
					    label-width="70"
					    placeholder="点击选择教育等级"
					    @click="showGrade = true"
					/>

                    <h5 style="padding-left: 16px">学校情况</h5>
                    <van-field
                        v-model="user.kindergarten"
                        name="kindergarten"
                        label="幼儿园"
                        label-width="70"
                        placeholder="幼儿园"
                    />
                    <van-field
                        v-model="user.primary_school"
                        name="primary_school"
                        label="小学"
                        label-width="70"
                        placeholder="小学"
                    />
                    <van-field
                        v-model="user.junior_school"
                        name="junior_school"
                        label="初中"
                        label-width="70"
                        placeholder="初中"
                    />
                    <van-field
                        v-model="user.high_school"
                        name="high_school"
                        label="高中"
                        label-width="70"
                        placeholder="高中"
                    />

                    <h5 style="padding-left: 16px">自我评价</h5>
                    <van-field
                        v-model="user.hobby"
                        name="hobby"
                        label="兴趣爱好"
                        label-width="70"
                        placeholder="兴趣爱好"
                    />
                    <van-field
                        v-model="user.self_evaluation"
						name="self_evaluation"
                        label-width="70"
                        rows="3"
                        autosize
                        label="自我评价"
                        type="textarea"
                        maxlength="200"
                        placeholder="输入自我评价"
                        show-word-limit
                    />
					<van-button block native-type="submit" color="#E26EFA" size="small" style="margin-top: 16px;font-size: 18px;padding: 18px;">
                        提交
                    </van-button>
                    <div class="controls">
                        <div class="skip" @click="onSkip">暂时跳过</div>
                        <!-- <div class="next-step">下一步</div> -->
                    </div>
                </van-form>
            </div>
        </div>
    </div>

    <van-popup v-model:show="showPicker" position="bottom">
        <van-datetime-picker
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirmBirthday"
            @cancel="showPicker = false"
        />
    </van-popup>

    <van-popup v-model:show="showArea" position="bottom">
        <van-area :area-list="areaList" @confirm="onConfirmArea" @cancel="showArea = false" />
    </van-popup>
	<van-popup v-model:show="showGrade" position="bottom">
	  <van-picker show-toolbar title="教育等级" :columns="columns" @confirm="onConfirmGrade" @cancel="showGrade = false" />
	  </van-popup>
</template>

<script>
import { onMounted } from 'vue'
import { Icon, Form,Button,Toast, Field, CellGroup, Radio, RadioGroup, DatetimePicker, Popup, Area,Picker } from 'vant'
import { areaList } from '@vant/area-data'
import { reactive, ref } from '@vue/reactivity'
import { userinfo,profile } from '@/service/user'
import { useRouter } from 'vue-router'

export default {
    components: {
        [Icon.name]: Icon,
		[Button.name]: Button,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [Popup.name]: Popup,
        [Area.name]: Area,
        [DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker
    },
    setup() {
        const user = reactive({
            name: null,
            gender: null,
            birthday: null,
            areas: null,
			grade: null,
            kindergarten: null,
            primary_school: null,
            junior_school: null,
            high_school: null,
            hobby: null,
            self_evaluation: null
        })
        const showPicker = ref(false)
        const minDate = new Date(1990, 0, 1)
        const maxDate = new Date(new Date().getFullYear() + 1, 0, 0)
        const showArea = ref(false)
		const showGrade = ref(false)
        const router = useRouter()
		onMounted(async () => {
		  let userinfos  = await userinfo();
		  user.name = userinfos.data.user.nickname
		  user.gender = userinfos.data.user.sex
		  user.grade = userinfos.data.user.grade
		  user.birthday = userinfos.data.user.birthday
		  user.areas = userinfos.data.user.area
		  user.kindergarten = userinfos.data.user.nursery_school
		  user.primary_school = userinfos.data.user.primary_school
		  user.junior_school = userinfos.data.user.juniorhigh_school
		  user.high_school = userinfos.data.user.high_school
		  user.hobby = userinfos.data.user.userSelfevaluation.hobby
		  user.self_evaluation = userinfos.data.user.userSelfevaluation.content
		})
        function onConfirmBirthday(value) {
            user.birthday = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()

            showPicker.value = false
        }

        function onConfirmArea(values) {
            showArea.value = false
            user.areas = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/')
        }
		function onConfirmGrade(values) {
		    showGrade.value = false
		    user.grade = values[1].text
		}

        function onSkip() {
			window.location.href = '/'
        }

        async function update(values) {
			await profile(values)
			Toast.success('完善信息成功');
			window.location.href = '/'
		}

        return {
            user,
            showPicker,
            minDate,
            maxDate,
            showArea,
            areaList,
            onConfirmBirthday,
            onConfirmArea,
            onSkip,
            update,
			showGrade,
			onConfirmGrade,
			columns: [
			        {
			          text: '小学',
			          children: [
			            {text: '一年级'},
			            {text: '二年级'},
						{text: '三年级'},
						{text: '四年级'},
						{text: '五年级'},
						{text: '六年级'},
			          ],
			        },
			        {
			          text: '初中',
			          children: [
			            {text: '初一'},
			            {text: '初二'},
						{text: '初三'},
			          ],
			        },
					{
					  text: '高中',
					  children: [
					    {text: '高一'},
					    {text: '高二'},
						{text: '高三'},
					  ],
					},
			      ],
        }
    }
}
</script>

<style lang="scss" scoped>
.profile {
    min-height: 100vh;
    padding-bottom: 32px;
    background-color: #ede2f7;
    box-sizing: border-box;

    .header {
        width: 100%;
        height: 354px;
        background: url('../assets/images/bg-user-header.png') center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;

        .header-inner {
            display: flex;
            align-items: center;
            box-sizing: border-box;

            .left,
            .right {
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                font-size: 44px;
            }

            .title {
                width: 100%;
                height: 100px;
                text-align: center;
                line-height: 100px;
                font-size: 36px;
                color: #000;
            }
        }
    }

    .auth-box {
        margin-top: -140px;

        .tabs {
            width: 500px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0 auto;

            & > .tab {
                flex: 1;
                height: 80px;
                text-align: center;
                font-size: 32px;
                color: #fff;

                &.active {
                    position: relative;
                    color: #fff400;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        width: 110px;
                        height: 6px;
                        margin-left: -55px;
                        background: #fff400;
                    }
                }
            }
        }

        .auth {
            width: 680px;
            margin: 0 auto;
            padding: 30px;
            border-radius: 5px;
            background-color: #fff;
            box-sizing: border-box;
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.1);

            .controls {
                display: flex;
                justify-content: space-between;
                margin-top: 60px;
                padding: 0 32px;
                font-size: 28px;

                .skip {
                    color: #999;
                }

                .next-step {
                    color: #273df0;
                }
            }
        }
    }
}
</style>