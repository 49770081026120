<template>
    <div class="radar-item" @click="$router.push('radar-show?rid='+radar.id)">
        <van-image class="cover" :src="radar.logo" fit="cover" />
        <div class="content" :class="radar.type==2?'xinxi':''">
            <div class="title">{{ radar.title }}</div>
            <div class="target" v-if="radar.type==1">参赛对象：{{ radar.participants }}</div>
            <div class="date" v-if="radar.type==1">比赛时间：{{ radar.start_comtime }}~{{ radar.end_comtime }}</div>
        </div>
    </div>
</template>

<script>
import { Image } from 'vant'
import { reactive } from '@vue/reactivity'
export default {
    components: {
        [Image.name]: Image
    },
    props: {
        radar: Object
    },
    setup(props) {
        const radar = reactive(props.radar)

        return {
            radar
        }
    }
}
</script>

<style lang="scss" scoped>
.radar-item {
    display: flex;
    padding: 35px 0;
    border-top: 1px solid #eee;

    .cover {
        flex: 0 0 auto;
        width: 145px;
        height: 100px;
    }

    .content {
        margin-left: 20px;

        .title {
            font-size: 26px;
            color: #4a90e2;
        }
		

        .target {
            margin-top: 18px;
            font-size: 20px;
            color: #666;
        }

        .date {
            margin-top: 6px;
            font-size: 20px;
            color: #666;
        }
    }
	.xinxi {
		display: flex;
		.title {
			align-self: center;
		}
	}
}
</style>