<template>
    <div class="training-item" @click="$router.push('/training-show?tid='+training.id)">
        <van-image class="cover" :src="training.logo" fit="cover" />
        <div class="content">
            <div class="title">{{ training.title }}</div>
			<div class="address">培训地址：{{ training.address }}</div>
            <div class="date">报名时间：{{ training.start_regtime }}~{{ training.end_regtime }}</div>
        </div>
    </div>
</template>

<script>
import { Image } from 'vant'
import { reactive } from '@vue/reactivity'

export default {
    components: {
        [Image.name]: Image
    },
    props: {
        training: Object
    },
    setup(props) {
        const training = reactive(props.training)

        return {
            training
        }
    }
}
</script>

<style lang="scss" scoped>
.training-item {
    display: flex;
    padding: 35px 0;
    border-top: 1px solid #eee;

    .cover {
        flex: 0 0 auto;
        width: 194px;
        height: 115px;
    }

    .content {
        position: relative;
        margin-left: 20px;

        .title {
            font-size: 26px;
            color: #4a4a4a;
        }
		
		.address {
		    margin-top: 18px;
		    font-size: 20px;
		    color: #a3a2a4;
			height: 80px;
		}

        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 20px;
            color: #a3a2a4;
        }
    }
}
</style>