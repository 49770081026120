<template>
    <div class="plan">
        <div class="banner">
            <img src="@/assets/images/img-banner-plan.png" />
        </div>
        <div class="plan-list">
			<van-list
			  v-model:loading="state.loading"
			  :finished="state.finished"
			  :finished-text="state.page>1 ? 没有更多了 : ''"
			  @load="onLoad"
			>
            <PlanItem v-for="plan in state.plans" :key="plan.id" :plan="plan" />
			</van-list>
        </div>
    </div>
</template>

<script>
import PlanItem from '@/components/PlanItem'
import { List } from 'vant'
import { reactive } from '@vue/reactivity'
import { planlist } from '@/service/plan'

export default {
	
    components: {
        PlanItem,
		[List.name]: List
    },
    setup() {
		const state = reactive({
		  plans: [],
		  loading: false,
		  finished: false,
		  page: 1,
		})
		const onLoad = async () => {
			let datalist  = await planlist({ page: state.page })
			state.plans = state.plans.concat(datalist.data.datalist)
			state.loading = false;
			if (datalist.data.datalist.length < 10) state.finished = true
			state.page = state.page + 1
		}

        return {
            state,
			onLoad
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    height: 196px;

    img {
        width: 100%;
        height: 100%;
    }
}

.plan-list {
    min-height: calc(100vh - 488px);
    padding: 16px 20px;
    background-color: #94d3d8;
}
</style>