<template>
    <div class="radar-show">
        <div class="subject">
            <van-image :src="require('@/assets/images/icon-news-1.png')" />
            <div class="title">{{state.radar.type==1?'竞赛':''}}详情</div>
        </div>

        <div class="block-body">
            <div class="title">{{state.radar.title}}</div>
            <div class="infos" v-if="state.radar.type==1">
                <p>参赛对象：{{state.radar.participants}}</p>
                <p>比赛时间：{{state.radar.start_comtime}}~{{state.radar.end_comtime}}</p>
                <p>参赛条件：{{state.radar.entry_conditions}}</p>
                <p>比赛级别：{{state.radar.level}}</p>
                <p>比赛奖项：{{state.radar.awards}}</p>
                <p>主办单位：{{state.radar.organizer}}</p>
            </div>
            <div class="intro" v-if="state.radar.type==1">比赛介绍</div>
            <div class="content" v-html="state.radar.info">
                
            </div>
        </div>

        <button class="btn-join" @click="href" v-if="state.radar.type==1">立刻参赛</button>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { Image } from 'vant'
import { reactive, ref } from '@vue/reactivity'
import { radardetail } from '@/service/radar'
import { useRoute, useRouter } from 'vue-router'
export default {
    components: {
        [Image.name]: Image,
    },
	setup() {
		const route = useRoute()
		const router = useRouter()
		const state = reactive({
		  radar: {}
		})
		onMounted(async () => {
		  const { rid } = route.query
		  const { data } = await radardetail('?rid='+rid)
		  state.radar = data
		})
		function href() {
			window.location.href = this.state.radar.url
		}
		return {
		   state,
		   href
		}
	}
}
</script>

<style lang="scss" scoped>
.radar-show {
    min-height: calc(100vh - 192px);
    padding-bottom: 40px;
    background-color: #ffe99b;

    .subject {
        display: flex;
        align-items: center;
        padding: 30px 50px;

        .van-image {
            width: 40px;
        }

        .title {
            margin: 0;
            margin-left: 18px;
            color: #4a4a4a;
            font-size: 32px;
            font-weight: bold;
        }
    }

    .block-body {
        width: 720px;
        margin: 0 auto;
        padding: 45px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 6px;

        .title {
            font-size: 36px;
            text-align: center;
        }

        .infos {
            width: 626px;
            margin-top: 50px;
            padding: 40px 48px;
            font-size: 28px;
            color: #4a4a4a;
            background-color: #eee;
            box-sizing: border-box;
            border-radius: 6px;
        }

        .intro {
            margin: 60px 0;
            font-size: 32px;
            font-weight: 700;
        }

        .content {
            line-height: 190%;
            font-size: 26px;
            color: #4a4a4a;
        }
    }

    .btn-join {
        display: block;
        width: 470px;
        height: 78px;
        margin: 40px auto 0;
        font-size: 28px;
        color: #fff;
        outline: none;
        border: none;
        background-image: linear-gradient(to top, #f34f5e 0%, #9f041b 100%);
        border-radius: 6px;
    }
}
</style>