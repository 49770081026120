<template>
	<div class="archive-list">
		<ArchvieHeader />
		<div class="subject">{{user.name||'**'}}的{{state.stagename}}成长手册</div>
		<van-tabs v-model:active="state.active" @click="changeTab" color="#4A90E2" title-active-color="#4A90E2"
			line-height="4px">
			<van-tab title="学科类">
				<div class="archive-body">
					<van-badge v-if="state.defaultlist['1']">
						<div class="archive-item">
							<van-image :src="state.defaultlist['1'].image"
								@click="preview(state.defaultlist['1'].image)" />
							<div class="title">{{state.defaultlist['1'].name}}</div>
						</div>
						<template #content>
							<van-icon name="cross" class="badge-icon" @click="delarchive(state.defaultlist['1'],'1')" />
						</template>
					</van-badge>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active+'&defaults=1')"
						v-else>
						<van-icon name="plus" />
						<div class="title">三好学生</div>
					</div>
					<van-badge v-if="state.defaultlist['2']">
						<div class="archive-item" v-if="state.defaultlist['2']">
							<van-image :src="state.defaultlist['2'].image"
								@click="preview(state.defaultlist['2'].image)" />
							<div class="title">{{state.defaultlist['2'].name}}</div>
						</div>
						<template #content>
							<van-icon name="cross" class="badge-icon" @click="delarchive(state.defaultlist['2'],'2')" />
						</template>
					</van-badge>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active+'&defaults=2')"
						v-else>
						<van-icon name="plus" />
						<div class="title">校内奖状</div>
					</div>
					<van-badge v-if="state.defaultlist['3']">
						<div class="archive-item" v-if="state.defaultlist['3']">
							<van-image :src="state.defaultlist['3'].image"
								@click="preview(state.defaultlist['3'].image)" />
							<div class="title">{{state.defaultlist['3'].name}}</div>
						</div>
						<template #content>
							<van-icon name="cross" class="badge-icon" @click="delarchive(state.defaultlist['3'],'3')" />
						</template>
					</van-badge>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active+'&defaults=3')"
						v-else>
						<van-icon name="plus" />
						<div class="title">成绩表</div>
					</div>
					<van-badge v-if="state.defaultlist['4']">
						<div class="archive-item" v-if="state.defaultlist['4']">
							<van-image :src="state.defaultlist['4'].image"
								@click="preview(state.defaultlist['4'].image)" />
							<div class="title">{{state.defaultlist['4'].name}}</div>
						</div>
						<template #content>
							<van-icon name="cross" class="badge-icon" @click="delarchive(state.defaultlist['4'],'4')" />
						</template>
					</van-badge>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active+'&defaults=4')"
						v-else>
						<van-icon name="plus" />
						<div class="title">校长寄语</div>
					</div>
					<black v-for="(index, key) in state.archiveList[0]">
						<van-badge>
							<div class="archive-item">
								<van-image :src="index.image" @click="preview(index.image)" />
								<div class="title">{{index.name}}</div>
							</div>
							<template #content>
								<van-icon name="cross" class="badge-icon" @click="delarchive(index,0,key)" />
							</template>
						</van-badge>
					</black>

					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active)">
						<van-icon name="plus" />
						<div class="title">添加证书</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="科技类">
				<div class="archive-body">
					<black v-for="index in state.archiveList[1]" :key="index">
						<van-badge>
							<div class="archive-item">
								<van-image :src="index.image" @click="preview(index.image)" />
								<div class="title">{{index.name}}</div>
							</div>
							<template #content>
								<van-icon name="cross" class="badge-icon" @click="delarchive(index,0,key)" />
							</template>
						</van-badge>
					</black>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active)">
						<van-icon name="plus" />
						<div class="title">添加证书</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="艺术类">
				<div class="archive-body">
					<black v-for="index in state.archiveList[2]" :key="index">
						<van-badge>
							<div class="archive-item">
								<van-image :src="index.image" @click="preview(index.image)" />
								<div class="title">{{index.name}}</div>
							</div>
							<template #content>
								<van-icon name="cross" class="badge-icon" @click="delarchive(index,0,key)" />
							</template>
						</van-badge>
					</black>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active)">
						<van-icon name="plus" />
						<div class="title">添加证书</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="体育类">
				<div class="archive-body">
					<black v-for="index in state.archiveList[3]" :key="index">
						<van-badge>
							<div class="archive-item">
								<van-image :src="index.image" @click="preview(index.image)" />
								<div class="title">{{index.name}}</div>
							</div>
							<template #content>
								<van-icon name="cross" class="badge-icon" @click="delarchive(index,0,key)" />
							</template>
						</van-badge>
					</black>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active)">
						<van-icon name="plus" />
						<div class="title">添加证书</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="其他类">
				<div class="archive-body">
					<black v-for="index in state.archiveList[4]" :key="index">
						<van-badge>
							<div class="archive-item">
								<van-image :src="index.image" @click="preview(index.image)" />
								<div class="title">{{index.name}}</div>
							</div>
							<template #content>
								<van-icon name="cross" class="badge-icon" @click="delarchive(index,0,key)" />
							</template>
						</van-badge>
					</black>
					<div class="archive-item plus-certificate"
						@click="$router.push('/archive/add?stage='+state.stage+'&type='+state.active)">
						<van-icon name="plus" />
						<div class="title">添加证书</div>
					</div>
				</div>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import {
		onMounted
	} from 'vue'
	import ArchvieHeader from '@/views/layouts/ArchiveHeader'
	import {
		Tabs,
		Tab,
		Grid,
		Badge,
		GridItem,
		Image,
		Icon,
		Toast,
		Dialog,
		ImagePreview
	} from 'vant'
	import {
		archivelist,
		delfile
	} from '@/service/archive'
	import {
		reactive,
		ref
	} from '@vue/reactivity'
	import {
		setLocal
	} from '@/common/js/utils'
	import {
		userinfo
	} from '@/service/user'
	import {
		useRoute
	} from 'vue-router'

	export default {
		components: {
			ArchvieHeader,
			[Tabs.name]: Tabs,
			[Tab.name]: Tab,
			[Grid.name]: Grid,
			[GridItem.name]: GridItem,
			[Image.name]: Image,
			[Icon.name]: Icon,
			[Toast.name]: Toast,
			[Badge.name]: Badge,
			[Dialog.name]: Dialog,
			[ImagePreview.name]: ImagePreview
		},
		setup() {
			const route = useRoute()
			const user = reactive({
				name: null,
			})
			const state = reactive({
				stage: 0,
				stagename: '',
				active: 0,
				archiveList: [{}, {}, {}, {}, {}],
				defaultlist: []
			})
			onMounted(async () => {
				const {
					stage
				} = route.query
				if (stage == 2) {
					setLocal('stage', 2);
					setLocal('stagename', '初中');
					state.stagename = '初中'
				} else if (stage == 3) {
					setLocal('stage', 3);
					setLocal('stagename', '高中');
					state.stagename = '高中'
				} else {
					setLocal('stage', 1);
					setLocal('stagename', '小学');
					state.stagename = '小学'
				}
				state.stage = stage
				let userinfos = await userinfo()
				user.name = userinfos.data.user.nickname
				init();
			})
			const init = async () => {

				const alist = await archivelist({
					stage: state.stage,
					type: state.active
				})
				state.archiveList[state.active] = alist.data.datalist
				state.defaultlist = alist.data.defaultlist
			}
			const changeTab = (name) => {
				init()
			}

			function preview(img) {
				ImagePreview([img]);


			}

			function delarchive(lists, defaults, keys) {
				Dialog.confirm({
						message: '确定要删除此证书吗！',
					}).then(async () => {
						await delfile({
							gid: lists.id
						})
						if (defaults != 0) {
							state.defaultlist[defaults] = undefined;
						} else {
							state.archiveList[state.active].splice(keys, 1);
						}
						Toast('删除成功')
					})
					.catch(() => {
						// on cancel
					});


			}
			return {
				user,
				state,
				init,
				changeTab,
				delarchive,
				preview
			}
		}
	}
</script>

<style lang="scss" scoped>
	.archive-list {
		padding-bottom: 16px;
	}

	.subject {
		display: flex;
		align-items: center;
		margin-top: 16px;
		padding: 30px 40px;
		color: #4a4a4a;
		background-color: #fff;
	}

	.archive-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 40px;
		background-color: #fff;

		.archive-item {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 325px;
			height: 240px;
			margin-top: 20px;
			background: #f3f3f3;

			&:nth-child(1) {
				margin-top: 0;
			}

			&:nth-child(2) {
				margin-top: 0;
			}

			.van-image {
				width: 200px;
			}

			.title {
				margin-top: 20px;
				font-size: 24px;
				color: #7f7f7f;
			}
		}

		.plus-certificate {
			font-size: 60px;
			color: #7f7f7f;

			.title {
				font-size: 32px;
			}
		}

		.badge-icon {
			display: block;
			font-size: 10px;
			line-height: 36px;
		}
	}
</style>
