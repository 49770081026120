<template>
	<div class="archive-preview">
		<ArchvieHeader />
		<div class="subject">
			<van-image :src="require('@/assets/images/icon-archive-print.png')" />
			<div class="title">档案打印</div>
		</div>
		<div class="archive-preview-inner">
			<div class="subject">
				<span>成为会员后可以下载</span>
				<button class="btn-download" @click="shuruemail()">下载PDF手册</button>
			</div>
			<div class="archive-preview-bodyx">
				<div class="cover">
					<div class="img">
						<van-image :src="require('@/assets/images/dybj.jpg')" />
					</div>
					<div class="infos">
			 		<div class="title">购买纸质打印手册</div>
						<van-form @submit="submit" class="tjform">
							<span class="tjtext">姓名</span>
							<van-field class="tjint" v-model="state.paymentform.name" type="text" name="name"
								:rules="[{ required: true, message: '请填写姓名' }]" />
							<span class="tjtext">手机</span>
							<van-field class="tjint" v-model="state.paymentform.phone" type="text" name="phone"
								:rules="[{ required: true, message: '请填写手机号' }]" />
							<span class="tjtext">地址</span>
							<van-field class="tjint" v-model="state.paymentform.address" type="textarea" name="address"
								:rules="[{ required: true, message: '请填写地址' }]" />
							<div class="tjprice">￥：56元</div>
							<van-button block native-type="submit" color="#E3690A" size="small"
								style="font-size: 16px;padding: 10px;width: 120px;">
								支付
							</van-button>
						</van-form>
					</div>
				</div>
			</div>
			<div id="demo" style="display: none;">
				<div class="archive-preview-body">
				    <div class="cover">
				        <div class="title">{{user.name||'**'}}的{{user.stagename}}成长手册</div>
				        <div class="infos">
				            <div>姓名：{{user.name||'**'}}</div>
				            <div>性别：{{user.sex}}</div>
				            <div>学校：{{user.school}}</div>
				        </div>
				        <div class="date">{{user.thisdate}}</div>
				    </div>
				</div>
				<div class="archive-preview-body1" v-for="preview in state.preview">
				    <div class="cover">
				        <div class="title">{{preview.certificate_type}}奖项</div>
				        <div :class="preview.certificate_type=='学科类'?'infos_shu':'infos'" v-for="files in preview.da">
							<div class="zs_img"><van-image class="img" :src="files.image" fit="contain" crossOrigin="anonymous" /></div>
				            
				            <div class="zs_text">
								<div>全国青少年科技创新大赛</div>
								<span v-if="preview.certificate_type!='学科类'">一等奖</span>
							</div>
				        </div>
						
				    </div>
				</div>
			</div>

		</div>
		<div id="canvasId"></div>
	</div>
	<span id="idfdata">{{state.pdfdata}}</span>
	<van-popup v-model:show="state.show" label-align="left" :style="{ width: '80%'}">
		<van-form @submit="handleDown">
			<van-field v-model="state.email" name="email" class="yx" label="发送邮箱" placeholder="请填写真实邮箱"
		 	:rules="[{ required: true, message: '请填写真实邮箱' }]" />
			<div style="margin: 16px;">
				<van-button block native-type="submit" round color="#fecd01" size="small"
					style="font-size: 16px;padding: 18px;margin-top: 16px;color: #000;">
					发送邮箱
				</van-button>
			</div>
		</van-form>
	</van-popup>
</template>

<script>
	import {
		onMounted,
		ref
	} from 'vue'
	import ArchvieHeader from '@/views/layouts/ArchiveHeader'
	import {
		userinfo
	} from '@/service/user'
	import {
		preview,
		generate_pdf
	} from '@/service/archive'
	import {
		Image,
		Toast,
		Popup,
		Form,
		Field,
		Button
	} from 'vant'
	import {
		getLocal
	} from '@/common/js/utils'
	import {
		reactive
	} from '@vue/reactivity'
	import htmlToPdf from '@/unit/htmlToPdf'
	export default {
		components: {
			ArchvieHeader,
			[Image.name]: Image,
			[Toast.name]: Toast,
			[Popup.name]: Popup,
			[Form.name]: Form,
			[Field.name]: Field,
			[Button.name]: Button,
		},
		setup() {
			const user = reactive({
				uid:null,
				name: null,
				sex: null,
				school: null,
				stage: null,
				stagename: null,
				thisdate: null,
				ismember:0
			})
			const state = reactive({
				preview: [],
				pdfdata: '',
				email: '',
				show: false,
				paymentform: {
					name: '',
			 	phone: '',
					address: ''
				}
			})
			onMounted(async () => {
				let userinfos = await userinfo()
				user.uid = userinfos.data.user.id
				user.name = userinfos.data.user.nickname
				user.ismember = userinfos.data.user.ismember
				user.sex = userinfos.data.user.sex == 2 ? '女' : '男'
				const stage = getLocal('stage')
				user.stagename = getLocal('stagename')
				if (stage == 2) {
					user.school = userinfos.data.user.juniorhigh_school
				} else if (stage == 3) {
					user.school = userinfos.data.user.high_school
				} else {
					user.stagename = '小学';
					user.school = userinfos.data.user.primary_school
				}
				let thisdate = new Date();
				user.thisdate = thisdate.getFullYear() + "-" + (thisdate.getMonth() + 1) + "-" + thisdate
					.getDate();

				let scdata = await preview({
					stage: stage
				})
				state.preview = scdata.data

			})

			function shuruemail() {
				if(user.ismember == 1) {
					this.state.show = true;
				} else {
					Toast('您暂未成为会员')
				}
			}
			function submit(values) {
				values.uid = user.uid
				values.stage = user.stagename
				
			}

			function handleDown(values) {
				Toast.loading({
					message: '手册下载中...',
					duration: 0,
					forbidClick: true,
				});
				document.getElementById("demo").style.display="block";
				let pdfname = user.name + '的' + user.stagename + '成长手册'
				htmlToPdf.downloadPDF(document.querySelector('#demo'), pdfname).then(async function onFulfilled(value) {
					let jieguo = await generate_pdf({
						base64pdf: value,
						pdfname: pdfname,
						email: values.email
					})
					document.getElementById("demo").style.display="none";
					Toast('成长手册已发送至您的邮箱，请注意查收！')


					//
				})

			}
			return {
				state,
				user,
				handleDown,
				shuruemail
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subject {
		display: flex;
		align-items: center;
		padding: 30px 40px;
		background-color: #fff;

		.van-image {
			width: 40px;
		}

		.title {
			margin: 0;
			margin-left: 18px;
			color: #4a4a4a;
			font-size: 30px;
		}
	}

	.archive-preview-inner {
		min-height: calc(100vh - 637px);
		padding-top: 10px;
		background-color: #c8dbfb;

		.subject {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			height: 120px;
			padding: 0 30px;
			font-size: 28px;
			color: #4a4a4a;
			background-color: #fff;

			.btn-download {
				width: 250px;
				height: 70px;
				line-height: 70px;
				text-align: center;
				font-size: 28px;
				color: #fff;
				background-color: #9013fe;
				border: none;
				border-radius: 16px;
				outline: none;
				transition: all 0.3s;

				&:active {
					background-color: #9013fe - #222;
				}
			}
		}
		.archive-preview-body {
		    padding: 14px 30px 23px;
		
		    .cover {
		        display: flex;
		        align-items: center;
		        flex-direction: column;
		        width: 690px;
		        height: 1037px;
		        background: url('../assets/images/bg-archive-preview-cover.png') no-repeat;
		        background-size: cover;
		
		        .title {
		            margin-top: 264px;
		            font-size: 40px;
		            color: #4a4a4a;
		        }
		
		        .infos {
		            margin-top: 80px;
		            font-size: 32px;
		            color: #4a4a4a;
		            line-height: 170%;
		        }
		
		        .date {
		            margin-top: 80px;
		            font-size: 32px;
		            color: #4a4a4a;
		        }
		    }
		}
		.archive-preview-body1 {
		    padding: 0px 30px 24px;
		
		    .cover {
		        display: flex;
		        flex-direction: column;
		        width: 690px;
		        height: 1037px;
		        background: url('../assets/images/bg-archive-preview-cover1.png') no-repeat;
		        background-size: cover;
		        .title {
		            margin-top: 80px;
					margin-left: 160px;
		            font-size: 36px;
		            color: #4a4a4a;
		        }
		        .infos {
		            margin-top: 80px;
		            font-size: 26px;
		            color: #000;
		            line-height: 170%;
					padding: 0 50px;
					display: flex;
					align-items: center;
					
					.zs_img{
						display: flex;
						align-items: center;
						width: 250px;
						height: 300px;
						.img{
							width:250px;
							height: 300px;
						}
					}
					.zs_text {
						width: 300px;
						padding-left: 30px;
						text-align: center;
					}
		        }
				.infos_shu {
					margin-top: 90px;
					font-size: 26px;
					color: #000;
					line-height: 170%;
					padding: 0 50px;
					display: flex;
					align-items: center;
					flex-direction: column;
					.zs_img{
						display: flex;
						align-items: center;
						width: 450px;
						height: 250px;
						.img{
							width:450px;
							height: 250px;
						}
					}
					.zs_text {
						margin-top: 10px;
						width: 300px;
						padding: 0 30px;
						text-align: center;
					}
				}
		    }
		}

		.archive-preview-bodyx {
			padding: 14px 0px 23px;

			.cover {
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				// flex-direction: column;

				.img {
					margin-top: 50px;
					font-size: 40px;
					color: #4a4a4a;
					width: 50%;
					text-align: center;

					.van-image {
						width: 320px;
					}
				}

				.infos {
					width: 50%;
					font-size: 32px;
					color: #4a4a4a;
					margin-top: 20px;
					line-height: 160%;
					.title {
						text-align: center;
						color:#50AC10;
						font-size: 36px;
						margin-bottom: 50px;
					}
					.tjform {
						margin: auto;
						width:300px;
						.tjtext {
							font-size: 26px;
						}
						.tjint {
							border: 1px solid #d5d5d5;
							border-radius: 12px;
							padding:4px 20px;
							width:300px;
							
						}
						.tjprice {
							color:red;
							margin: 30px 0px 5px;
						}
					}
					
				}
			}
		}

	}

	.van-cell {
		line-height: 50px;
	}
</style>
