<template>
    <div class="science-show">
        <div class="banner">
            <img :src="state.sciences.logo" />
        </div>

        <div class="title">{{state.sciences.title}}</div>

        <div class="metas" v-if="state.sciences.type==2">
            <div>出发日期：{{state.sciences.departure_time}}</div>
            <div>行程套餐：{{state.sciences.journey_combo}}</div>
            <div>出游人数：{{state.sciences.travelnum}}</div>
            <div class="operations">
                <div class="price">￥{{state.sciences.price}}</div>
                <button class="btn-book" @click="bm">立刻预定</button>
            </div>
        </div>

        <div class="subject">
			<span>介绍</span>
			<button class="btn-book" @click="bm" v-if="state.sciences.type==1 && state.sciences.link != ''">详情</button>
		</div>

        <div class="content" v-html="state.sciences.info">
            
        </div>

        
    </div>
	<van-popup v-model:show="state.showbm" label-align="left" :style="{ width: '70%' }">
		<van-form @submit="rsubmit">
		  <van-field
		    v-model="state.name"
		    name="name"
		    label="姓名"
		    placeholder="输入姓名"
		    :rules="[{ required: true, message: '请填写姓名' }]"
		  />
		  <van-field
		    v-model="state.phone"
		    type="phone"
		    name="phone"
		    label="手机号"
		    placeholder="输入手机号"
		    :rules="[{ required: true, message: '请填写手机号' }]"
		  />
		  <van-field
		    v-model="state.num"
		    name="num"
		    label="出游人数"
		    placeholder="输入出游人数"
		  />
		  <div style="margin: 16px;">
		    <van-button block native-type="submit" color="#fecd01" size="small" style="margin-top: 16px;color: #000;">
		        报名预定
		    </van-button>
		  </div>
		</van-form>
	</van-popup>
</template>

<script>
import { onMounted } from 'vue'
import { Image,Popup,Form,Field,Button,Toast } from 'vant'
import { detail,reserve_submit } from '@/service/research'
import { reactive, ref } from '@vue/reactivity'
import { userinfo } from '@/service/user'
import { useRoute } from 'vue-router'
export default {
	components: {
		[Popup.name]: Popup,
		[Form.name]: Form,
		[Field.name]: Field,
		[Button.name]: Button,
	},
	setup() {
		const route = useRoute()
		const state = reactive({
		  showbm: false,
		  sciences: {},
		  name:'',
		  phone:'',
		  num:'',
		  sid:0
		})
		onMounted(async () => {
		  const { sid } = route.query
		  state.sid = sid
		  const { data } = await detail('?sid='+sid)
		  state.sciences = data
		  console.log(state.sciences)
		})
		async function bm() {
			if(state.sciences.type==2) {
				let userinfos = await userinfo();
				this.state.name = userinfos.data.user.nickname
				this.state.phone = userinfos.data.user.phone
				this.state.showbm = true;
			} else {
				window.location.href = state.sciences.link
			}
			
		}
		async function rsubmit(values) {
			
			values.sid = state.sid
			console.log(values)
			await reserve_submit(values)
			Toast.success('预定成功，等待工作人员联系');
			state.showbm = false;
		}
		return {
		    bm,
			state,
			rsubmit
		}
	}
}
</script>

<style lang="scss" scoped>
.banner {
    height: 280px;

    img {
        width: 100%;
        height: 100%;
    }
}

.title {
    padding: 30px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    background-color: #fff;
}

.metas {
    margin-top: 6px;
    padding: 40px 30px;
    font-size: 24px;
    color: #4a4a4a;
    line-height: 50px;
    background-color: #fff;

    .operations {
        display: flex;
        align-items: center;

        .price {
            flex: 1;
            font-size: 30px;
            font-weight: 700;
            color: #d0021b;
        }

        .btn-book {
            flex: 0 0 auto;
            width: 200px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 28px;
            color: #000;
            background-color: #fecd01;
            border: none;
            outline: none;
        }
    }
}

.subject {
    padding: 30px;
    font-size: 32px;
    font-weight: 700;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn-book {
	    flex: 0 0 auto;
	    width: 150px;
	    height: 70px;
	    line-height: 70px;
	    text-align: center;
	    font-size: 28px;
	    color: #000;
	    background-color: #fecd01;
	    border: none;
	    outline: none;
	}
}

.content {
    padding: 30px;
    line-height: 190%;
    color: #4a4a4a;
    background-color: #fff;
}

.consulting-phone {
    padding: 30px;
    text-align: center;
    font-size: 28px;
    color: #4a90e2;
    background-color: #fff;
}
</style>