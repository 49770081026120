<template>
    <van-nav-bar
        title="科学少年社"
        left-arrow
        left-text="返回"
        fixed
        @click-left="$router.back(-1)"
        @click-right="$router.push('/')"
    >
        <template #right>
            <van-icon name="wap-home-o" style="font-size: 18px" />
        </template>
    </van-nav-bar>

    <div class="safe-top-height"></div>

</template>

<script>
import { NavBar, Icon } from 'vant'

export default {
    components: {
        [NavBar.name]: NavBar,
        [Icon.name]: Icon
    }
}
</script>

<style lang="scss" scoped>
.safe-top-height {
    height: 92px;
}
</style>