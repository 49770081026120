<template>
	<div class="member">
		<div class="header">
			<div class="header-inner">
				<div class="left" @click="$router.back(-1)"><van-icon name="arrow-left" /></div>
				<div class="title">科学少年社</div>
				<div class="right"></div>
			</div>

			<div class="member-info">
				<van-uploader :before-read="beforeRead" :after-read="afterRead"><van-image round :src="user.avatar" class="avatar" /></van-uploader>

				<div class="infos">
					<div class="name">{{ user.name }}</div>
					<div class="phone">
						<span>{{ user.grade }}</span>
						<span v-if="user.grade">&nbsp;&nbsp;</span>
						<span>{{ user.phone }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="member-body">
			<div class="banner" @click="jump('cost')"><img src="@/assets/images/img-banner-member.png" /></div>

			<div class="tools">
				<div class="subject">服务工具</div>

				<van-grid :border="false" :column-num="3">
					<van-grid-item
						v-for="value in operations"
						:key="value.alias"
						:icon="require(`@/assets/images/icon-member-${value.alias}.png`)"
						:text="value.name"
						@click="jump(value.alias)"
					/>
				</van-grid>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted } from 'vue'
import { Icon,Uploader,Image,Grid,GridItem,Toast,Button } from 'vant'
import { reactive,ref } from '@vue/reactivity'
import { userinfo,upavatar } from '@/service/user'
import { useRouter } from 'vue-router'
import Compressor from 'compressorjs';

export default {
	components: {
		[Icon.name]: Icon,
		[Image.name]: Image,
		[Uploader.name]: Uploader,
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
		[Toast.name]: Toast,
		[Button.name]: Button,
	},
	setup() {
		const fileList = ref([])
		const user = reactive({
			name: null,
			phone: null,
			avatar: null,
			grade: null,
		})
		const router = useRouter()
		onMounted(async () => {
			let userinfos = await userinfo();
			user.name = userinfos.data.user.nickname
			user.phone = userinfos.data.user.phone
			user.avatar = userinfos.data.user.avatar
			user.grade = userinfos.data.user.grade
		})
		const operations = reactive([{
				name: '修改密码',
				alias: 'password'
			},
			{
				name: '修改信息',
				alias: 'change'
			},
			{
				name: '会员续费',
				alias: 'cost'
			}
		])
		 const beforeRead = (file) =>
		       new Promise((resolve) => {
		         // compressorjs 默认开启 checkOrientation 选项
		         // 会将图片修正为正确方向
		         new Compressor(file, {
					 quality:0.6,
					 maxWidth:300,
					 maxHeight:300,
		           success: resolve,
		           error(err) {
		             console.log(err.message);
		           },
		         });
		       });
			async function afterRead(file) {
		      //console.log(file);
			  user.avatar = file.content
			  Toast.loading({
			    message: '头像上传中...',
				duration:12000,
			    forbidClick: true,
			  });
			  await upavatar({
				  avatarimg:user.avatar
			  });
			  Toast('头像修改成功')
		    }
			function jump(name) {
				if (name == 'password') {
					router.push('/forget-password')
				} else if (name == 'cost') {
					Toast('工程师正在开发中，敬请期待')
				} else {
					router.push('/Profile')
				}

			}

			return {
				operations,
				user,
				jump,
				fileList,
				beforeRead,
				afterRead,
			}
		}
	}
</script>

<style lang="scss" scoped>
.member {
	min-height: calc(100vh - 100px);
	background-color: #ede2f7;

	.header {
		width: 100%;
		height: 354px;
		background: url('../assets/images/bg-user-header.png') center no-repeat;
		background-size: 100% 100%;

		.header-inner {
			display: flex;
			align-items: center;

			.left,
			.right {
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
				font-size: 44px;
			}

			.title {
				width: 100%;
				height: 100px;
				text-align: center;
				line-height: 100px;
				font-size: 36px;
				color: #000;
			}
		}
	}

	.member-info {
		display: flex;
		align-items: center;
		height: 254px;
		padding: 0 64px;

		.avatar {
			width: 145px;
			height: 145px;
		}

		.infos {
			margin-left: 45px;
			color: #fff;

			.name {
				font-size: 36px;
			}

			.phone {
				margin-top: 20px;
				font-size: 30px;
			}
		}
	}

	.member-body {
		padding: 0 20px;

		.banner {
			height: 125px;
			margin: 30px 0;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.tools {
			padding: 40px 40px 20px;
			background-color: #fff;

			.subject {
				margin-bottom: 20px;
				font-size: 30px;
				font-weight: 700;
			}
		}
	}
}
</style>
