<template>
    <div class="archive-preview">
        <ArchvieHeader />
        <div class="archive-preview-inner">
            <div class="subject">
                <span>{{user.name||'**'}}的{{user.stagename}}成长手册-【预览]</span>
                <!-- <button class="btn-download" @click="shuruemail()">下载手册</button> -->
            </div>
			<div id="demo">
				<div class="archive-preview-body">
				    <div class="cover">
				        <div class="title">{{user.name||'**'}}的{{user.stagename}}成长手册</div>
				        <div class="infos">
				            <div>姓名：{{user.name||'**'}}</div>
				            <div>性别：{{user.sex}}</div>
				            <div>学校：{{user.school}}</div>
				        </div>
				        <div class="date">{{user.thisdate}}</div>
				    </div>
				</div>
				<div class="archive-preview-body1" v-for="preview in state.preview">
				    <div class="cover">
				        <div class="title">{{preview.certificate_type}}奖项</div>
				        <div :class="preview.certificate_type=='学科类'?'infos_shu':'infos'" v-for="files in preview.da">
							<div class="zs_img"><van-image class="img" :src="files.image" fit="contain" crossOrigin="anonymous" /></div>
				            
				            <div class="zs_text">
								<div>全国青少年科技创新大赛</div>
								<span v-if="preview.certificate_type!='学科类'">一等奖</span>
							</div>
				        </div>
						
				    </div>
				</div>
			</div>
            
        </div>
		<div id="canvasId"></div>
    </div>
	<span id="idfdata">{{state.pdfdata}}</span>
	<van-popup v-model:show="state.show" label-align="left" :style="{ width: '80%'}">
		<van-form @submit="handleDown">
		  <van-field
		    v-model="state.email"
		    name="email"
			class="yx"
		    label="发送邮箱"
		    placeholder="请填写真实邮箱"
		    :rules="[{ required: true, message: '请填写真实邮箱' }]"
		  />
		  <div style="margin: 16px;">
		    <van-button block native-type="submit" round color="#fecd01" size="small" style="font-size: 16px;padding: 18px;margin-top: 16px;color: #000;">
		        发送邮箱
		    </van-button>
		  </div>
		</van-form>
	</van-popup>
</template>

<script>
import { onMounted,ref } from 'vue'
import ArchvieHeader from '@/views/layouts/ArchiveHeader'
import { userinfo } from '@/service/user'
import { preview,generate_pdf } from '@/service/archive'
import { Image,Toast,Popup,Form,Field,Button } from 'vant'
import { getLocal } from '@/common/js/utils'
import { reactive } from '@vue/reactivity'
import htmlToPdf from '@/unit/htmlToPdf'
export default {
    components: {
        ArchvieHeader,
		[Image.name]: Image,
		[Toast.name]: Toast,
		[Popup.name]: Popup,
		[Form.name]: Form,
		[Field.name]: Field,
		[Button.name]: Button,
    },
	setup() {
		const user = reactive({
		    name: null,
			sex:null,
			school:null,
			stage:null,
			stagename:null,
			thisdate:null,
		})
		const state = reactive({
		  preview:[],
		  pdfdata:'',
		  email:'',
		  show:false
		})
		onMounted(async () => {	
			let userinfos  = await userinfo()
			user.name = userinfos.data.user.nickname
			user.sex = userinfos.data.user.sex==2?'女':'男'
			const stage = getLocal('stage')
			user.stagename = getLocal('stagename')
			if(stage == 2) {
				user.school = userinfos.data.user.juniorhigh_school
			} else if(stage == 3) {
				user.school = userinfos.data.user.high_school
			} else {
				user.stagename = '小学';
				user.school = userinfos.data.user.primary_school
			}
			let thisdate = new Date();
			user.thisdate = thisdate.getFullYear() + "-" + (thisdate.getMonth() + 1) + "-" + thisdate.getDate();
			
			let scdata  = await preview({
				stage:stage
			})
			state.preview = scdata.data
			
		})
		function shuruemail() {
			this.state.show = true;
			
		}
		
		function handleDown(values) {
			Toast.loading({
			  message: '手册下载中...',
			  duration: 0,
			  forbidClick: true,
			});
			let pdfname = user.name + '的'+user.stagename+'成长手册'
		    htmlToPdf.downloadPDF(document.querySelector('#demo'), pdfname).then(async function onFulfilled(value){
				let jieguo = await generate_pdf({
					base64pdf:value,
					pdfname:pdfname,
					email:values.email
				})
				Toast('成长手册已发送至您的邮箱，请注意查收！')
				
				
				//
			})
			
		}
	    return {
			state,
			user,
			handleDown,
			shuruemail
	    }
	}
}
</script>

<style lang="scss" scoped>
.archive-preview-inner {
    min-height: calc(100vh - 637px);
    padding-top: 10px;
    background-color: #c8dbfb;

    .subject {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        padding: 0 30px;
        font-size: 30px;
        color: #4a4a4a;
        background-color: #fff;

        .btn-download {
            width: 190px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 30px;
            color: #fff;
            background-color: #9013fe;
            border: none;
            border-radius: 16px;
            outline: none;
            transition: all 0.3s;

            &:active {
                background-color: #9013fe - #222;
            }
        }
    }

    .archive-preview-body {
        padding: 14px 30px 23px;

        .cover {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 690px;
            height: 1037px;
            background: url('../assets/images/bg-archive-preview-cover.png') no-repeat;
            background-size: cover;

            .title {
                margin-top: 264px;
                font-size: 40px;
                color: #4a4a4a;
            }

            .infos {
                margin-top: 80px;
                font-size: 32px;
                color: #4a4a4a;
                line-height: 170%;
            }

            .date {
                margin-top: 80px;
                font-size: 32px;
                color: #4a4a4a;
            }
        }
    }
	.archive-preview-body1 {
	    padding: 0px 30px 24px;
	
	    .cover {
	        display: flex;
	        flex-direction: column;
	        width: 690px;
	        height: 1037px;
	        background: url('../assets/images/bg-archive-preview-cover1.png') no-repeat;
	        background-size: cover;
	        .title {
	            margin-top: 80px;
				margin-left: 160px;
	            font-size: 36px;
	            color: #4a4a4a;
	        }
	        .infos {
	            margin-top: 80px;
	            font-size: 26px;
	            color: #000;
	            line-height: 170%;
				padding: 0 50px;
				display: flex;
				align-items: center;
				
				.zs_img{
					display: flex;
					align-items: center;
					width: 250px;
					height: 300px;
					.img{
						width:250px;
						height: 300px;
					}
				}
				.zs_text {
					width: 300px;
					padding-left: 30px;
					text-align: center;
				}
	        }
			.infos_shu {
				margin-top: 90px;
				font-size: 26px;
				color: #000;
				line-height: 170%;
				padding: 0 50px;
				display: flex;
				align-items: center;
				flex-direction: column;
				.zs_img{
					display: flex;
					align-items: center;
					width: 450px;
					height: 250px;
					.img{
						width:450px;
						height: 250px;
					}
				}
				.zs_text {
					margin-top: 10px;
					width: 300px;
					padding: 0 30px;
					text-align: center;
				}
			}
	    }
	}
}
.van-cell {
		line-height: 70px;
	}
</style>