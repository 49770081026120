<template>
    <div class="science-item" @click="$router.push('/science-show?sid='+science.id)">
        <van-image class="cover" :src="science.logo" fit="cover" />
        <div class="content">
            <div class="title">{{ science.title }}</div>
            <div class="date">{{ science.departure_time }}</div>
        </div>
    </div>
</template>

<script>
import { Image } from 'vant'
import { reactive } from '@vue/reactivity'
export default {
    components: {
        [Image.name]: Image
    },
    props: {
        science: Object
    },
    setup(props) {
        const science = reactive(props.science)

        return {
            science
        }
    }
}
</script>

<style lang="scss" scoped>
.science-item {
    display: flex;
    padding: 35px 0;
    border-top: 1px solid #eee;

    .cover {
        flex: 0 0 auto;
        width: 145px;
        height: 100px;
    }

    .content {
        position: relative;
        margin-left: 20px;

        .title {
            font-size: 28px;
            font-weight: bold;
            color: #4a4a4a;
        }

        .date {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 20px;
            color: #a3a2a4;
        }
    }
}
</style>