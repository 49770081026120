<template>
	<div class="search-box">
	    <input type="text" placeholder="查找好物" v-model="state.keyword" />
	    <button class="btn-search" @click="search()">
	        <img src="@/assets/images/icon-search.png" />
	    </button>
	</div>
    <div class="plan">
        <div class="banner">
            <img src="@/assets/images/img-banner-plan.png" />
        </div>
        <div class="plan-list">
			<van-list
			  v-model:loading="state.loading"
			  :finished="state.finished"
			  :finished-text="state.page>1 ? 没有更多了 : ''"
			  @load="onLoad"
			  class="shop"
			>
            <ShopItem v-for="shop in state.shops" :key="shop.id" :shop="shop" />
			</van-list>
        </div>
    </div>
</template>

<script>
import ShopItem from '@/components/ShopItem'
import { List } from 'vant'
import { reactive } from '@vue/reactivity'
import { goodthinglist } from '@/service/shop'

export default {
	
    components: {
        ShopItem,
		[List.name]: List
    },
    setup() {
		const state = reactive({
		  shops: [],
		  loading: false,
		  finished: false,
		  page: 1,
		  keyword:''
		})
		const onLoad = async () => {
			let datalist  = await goodthinglist({ keyword: state.keyword,page: state.page })
			console.log(datalist);
			state.shops = state.shops.concat(datalist.data.datalist)
			state.loading = false;
			if (datalist.data.datalist.length < 10) state.finished = true
			state.page = state.page + 1
		}
		function search() {
			state.finished = false
			state.loading = true
			state.page = 1
			state.shops = [];
			onLoad()
		}

        return {
            state,
			onLoad,
			search
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    height: 196px;

    img {
        width: 100%;
        height: 100%;
    }
}
.shop {
	padding: 0px 20px;
}

.plan-list {
    min-height: calc(100vh - 488px);
    padding: 16px 20px;
    background-color: #94d3d8;
}
</style>