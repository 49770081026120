import axios from '../utils/axios'

export function archivelist(params) {//竞赛列表
  return axios.post('/GrowthFile/list', params);
}

export function preview(params) {//成长手册预览
  return axios.post('/GrowthFile/preview', params);
}
export function delfile(params) {//成长手册预览
  return axios.post('/GrowthFile/delfile', params);
}
export function generate_pdf(params) {//成长手册预览
  return axios.post('/GrowthFile/generate_pdf', params);
}

export function gfsubmit(params) {//竞赛列表
  return axios.post('/GrowthFile/gfsubmit', params);
}



