<template>
    <div class="archive-header">
        <div class="banner" v-if="state.carousel.length">
            <van-swipe class="my-swipe" :autoplay="3000" height="140">
              <van-swipe-item v-for="image in state.carousel" :key="image.id" @click="href(image.url)">
                <img class="my-swipe-img" :src="image.img" />
              </van-swipe-item>
            </van-swipe>
        </div>
        <van-grid :border="false" :column-num="4">
            <van-grid-item
                v-for="value in operations"
                :key="value.alias"
                :icon="require(`@/assets/images/icon-archive-${value.alias}.png`)"
                :text="value.name"
                @click="jump(value.alias)"
            />
        </van-grid>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { Icon,Swipe, SwipeItem, Image, Grid, GridItem } from 'vant'
import { reactive } from '@vue/reactivity'
import { carousellist } from '@/service/carousel'
import { useRouter } from 'vue-router'

export default {
    components: {
        [Icon.name]: Icon,
        [Image.name]: Image,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
    },
    setup() {
        const operations = reactive([
            {
                name: '完善信息',
                alias: 'profile'
            },
            {
                name: '阶段切换',
                alias: 'switch'
            },
            {
                name: '档案预览',
                alias: 'preview'
            },
            {
                name: '档案打印',
                alias: 'print'
            }
        ])
		const state = reactive({
		    carousel: [],
		})
        const router = useRouter()
		onMounted(async () => {
		  let carousels = await carousellist({
			  type:2
		  });
		  state.carousel = carousels.data.carousel
		  
		})
        function jump(alias) {
            if (alias == 'switch') {
                router.push('/archive')
                return
            }

            if (alias === 'print') {
				router.push('/archive/printing')
                return
            }

            if (alias === 'profile') {
                router.push('/profile')
                return
            }

            router.push(`/archive/${alias}`)
        }
		function href(url) {
		    if (url) {
		        window.location.href = url
		    }
		}

        return {
            operations,
            jump,
			state,
			href
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    height: 265px;

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 280px;
    	height:280px;
        text-align: center;
    }
    .my-swipe .my-swipe-img {
        width: 100%;
        height: 280px;
    }
}
</style>