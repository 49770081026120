<template>
    <div class="register">
        <div class="header">
            <div class="header-inner">
            	<div class="left" @click="$router.push('/')">
            		<van-icon name="arrow-left" />
            	</div>
            	<div class="title">科学少年社</div>
            	<div class="right"></div>
            </div>
        </div>
        <div class="auth-box">
            <div class="tabs">
                <router-link to="/login" class="tab">登录</router-link>
                <router-link to="/register" class="tab active">注册</router-link>
            </div>
            <div class="auth">
                <van-form @submit="register">
                    <van-field
                        v-model="user.phone"
                        name="phone"
                        label="手机号"
                        label-width="60"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                    />
                    <van-field
                        v-model="user.password"
                        type="password"
                        name="password"
                        label="密码"
                        label-width="60"
                        placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                    />
                    <van-field
                        v-model="user.sms"
						name="sms"
                        center
                        clearable
                        label="验证码"
                        label-width="60"
                        placeholder="验证码"
                        :rules="[{ required: true, message: '请填写验证码' }]"
                    >
                        <template #button>
                            <button type="button" class="btn-sendsms" @click="sendSms" :disabled="current.seconds">
                                {{ current.seconds ? `${current.seconds}s` : '发送验证码' }}
                            </button>
                        </template>
                    </van-field>
                    <van-button block native-type="submit" color="#E26EFA" size="small" style="margin-top: 16px;font-size: 18px;padding: 18px;">
                        注册
                    </van-button>
                </van-form>
            </div>
            <div class="protocol">
                <van-checkbox v-model="isRead" checked-color="#E26EFA">我已阅读并同意《用户注册协议》</van-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { Icon,Checkbox, Toast, Form, Field, CellGroup, Button } from 'vant'
import { useCountDown } from '@vant/use'
import { getverificationcode,reg } from '@/service/identity'
import md5 from 'js-md5'
import { useRouter } from 'vue-router'
export default {
    components: {
		[Icon.name]: Icon,
        [Toast.name]: Toast,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup
    },
    setup() {
        const isRead = ref(false)
        const user = reactive({
            phone: null,
            password: null,
            sms: null
        })
        const router = useRouter()
        const time = ref(60000)
        const countDown = useCountDown({
            time: time.value,
            onFinish() {
                countDown.reset()
            }
        })
        async function sendSms() {
			if (!/^1[3456789]\d{9}$/.test(this.user.phone)) {
			    Toast('请填写正确的手机号')
			} else {
				await getverificationcode({
					"phone": this.user.phone
				})
			    countDown.start()
			    Toast('验证码发送成功')
			}
            
        }

        async function register(values) {
			if(!isRead.value) {
				Toast('请点击我已阅读并同意');
				return;
			}
			if (!/\d{6}/.test(values.password)) {	
			    Toast('密码最少6位')
			} else {
				await reg({
					"phone": values.phone,
					"password": md5(values.password),
					"code": values.sms
				})
			   router.push('/login')
			}
            
        }

        return {
            isRead,
            user,
            time,
            current: countDown.current,
            sendSms,
            register
        }
    }
	
}
</script>

<style lang="scss" scoped>
.register {
    height: 100vh;
    background-color: #ede2f7;
    .header {
        width: 100%;
        height: 354px;
        background: url('../assets/images/bg-user-header.png') center no-repeat;
        background-size: 100% 100%;

        .header-inner {
        	display: flex;
        	align-items: center;
        
        	.left,
        	.right {
        		width: 100px;
        		height: 100px;
        		line-height: 100px;
        		text-align: center;
        		font-size: 44px;
        	}
        
        	.title {
        		width: 100%;
        		height: 100px;
        		text-align: center;
        		line-height: 100px;
        		font-size: 36px;
        		color: #000;
        	}
        }
    }
	.van-cell {
		line-height: 100px;
	}
    .auth-box {
        margin-top: -140px;

        .tabs {
            width: 500px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0 auto;

            & > .tab {
                flex: 1;
                height: 80px;
                text-align: center;
                font-size: 36px;
                color: #fff;

                &.active {
                    position: relative;
                    color: #fff400;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        width: 110px;
                        height: 6px;
                        margin-left: -55px;
                        background: #fff400;
                    }
                }
            }
        }

        .auth {
            width: 680px;
            margin: 0 auto;
            padding: 30px;
            border-radius: 5px;
            background-color: #fff;
            box-sizing: border-box;
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.1);

            .btn-sendsms {
                height: 64px;
                color: #005fff;
                outline: none;
                border: none;
                border-radius: 4px;
                background: none;
            }
        }

        .protocol {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 33px;
            font-size: 28px;
            color: #444;
        }
    }
}
</style>