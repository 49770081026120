<template>
    <div class="archive-add">
        <div class="archive-add-inner">
            <div class="subject">上传证书</div>
			<van-form @submit="onSubmit">
            <div class="form-field">
                <van-uploader v-model="fileList" :max-count="1" :before-read="beforeRead" :after-read="afterRead" multiple>
                    <div class="upload-control">
                        <van-icon name="photograph" />
                        <div class="title">证书照片</div>
                    </div>
                </van-uploader>
				
                <div class="form-item">
                    <van-field
                        v-model="forms.name"
                        name="name"
                        placeholder="证书名称"
                      />
                </div>
                <div class="form-item">
					<van-field
					    v-model="forms.awards"
					    name="awards"
					    placeholder="填写奖项"
					  />
                </div>
                <div class="form-item">
					<van-field
					    v-model="forms.get_time"
					    name="get_time"
					    placeholder="获得时间"
					  />
                </div>
                <div class="form-item">
					<van-field
					    v-model="forms.remark"
					    name="remark"
						type="textarea"
					    label=""
					    placeholder="备注"
					  />
                </div>
                <div class="form-item">
                    <button class="btn-submit" native-type="submit">提交</button>
                </div>
            </div>
			</van-form>
        </div>
    </div>
</template>
 
<script>
import { Uploader,Toast, Icon,Field, Form, Button } from 'vant'
import { ref } from '@vue/reactivity'
import { reactive } from '@vue/reactivity'
import { gfsubmit } from '@/service/archive'
import { useRoute,useRouter } from 'vue-router'
import Compressor from 'compressorjs';

export default {
    components: {
		[Toast.name]: Toast,
        [Uploader.name]: Uploader,
        [Icon.name]: Icon,
		[Form.name]: Form,
		[Field.name]: Field,
		[Button.name]: Button,
    },
    setup() {
		const route = useRoute()
		const router = useRouter()
        const fileList = ref([])
		const forms = reactive({
			stage:0,
			type:0,
			defaults:0,
		    name: '',
		    awards: '',
			get_time: '',
			remark: '',
			file:''
		})
		const beforeRead = (file) =>
			new Promise((resolve) => {
				// compressorjs 默认开启 checkOrientation 选项
				// 会将图片修正为正确方向
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 800,
					maxHeight: 800,
					success: resolve,
					error(err) {
						console.log(err.message);
					},
				});
			});
		function afterRead(file) {
			forms.file = file.content
		}
		async function onSubmit(value) {
			
			
			Toast.loading({
			  message: '上传证书中...',
			  duration: 0,
			  forbidClick: true,
			});
			const { stage } = route.query
			const { type } = route.query
			const { defaults } = route.query
			forms.stage = stage
			forms.type = type
			forms.defaults = defaults
			await gfsubmit({
				"stage": forms.stage,
				"defaults": forms.defaults,
				"type": forms.type,
				"name": forms.name,
				"awards": forms.awards,
				"get_time": forms.get_time,
				"remark": forms.remark,
				"file": forms.file,
			})
			Toast('上传证书成功')
			router.push('/archive/list?stage='+forms.stage)
		}
        return {
            fileList,
			beforeRead,
			afterRead,
			onSubmit,
			forms
        }
    }
}
</script>

<style lang="scss" scoped>
.archive-add {
    min-height: calc(100vh - 192px);
    padding: 40px 20px;
    background-color: #e1ecfa;
    box-sizing: border-box;

    .archive-add-inner {
        padding: 40px;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 8px;

        .subject {
            font-size: 32px;
            font-weight: 700;
        }

        .form-field {
            margin-top: 40px;

            .upload-control {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 160px;
                height: 160px;
                font-size: 30px;
                color: #fff;
                background-color: #4a90e2;
                border-radius: 10px;

                .van-icon {
                    margin-bottom: 16px;
                    font-size: 60px;
                }
            }

            .form-item {
                margin-top: 20px;

                input {
                    width: 100%;
                    padding: 20px;
                    font-size: 30px;
                    color: #b8b8b8;
                    border: none;
                    background-color: #ececec;
                    box-sizing: border-box;
                    border-radius: 8px;
                }
				.van-field{
					width: 100%;
					padding: 20px;
					font-size: 30px;
					color: #b8b8b8;
					border: none;
					background-color: #ececec;
					box-sizing: border-box;
					border-radius: 8px;
				}

                textarea {
                    width: 100%;
                    min-height: 200px;
                    padding: 20px;
                    font-size: 30px;
                    color: #b8b8b8;
                    border: none;
                    background-color: #ececec;
                    box-sizing: border-box;
                    border-radius: 8px;
                }

                .btn-submit {
                    display: block;
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    font-size: 28px;
                    color: #fff;
                    border: none;
                    background-color: #4a90e2;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>