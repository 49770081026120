<template>
    <div class="training-show">
        <div class="banner">
            <img :src="state.training.logo" />
        </div>
        <div class="wrapper">
            <div class="title">{{state.training.title}}</div>
            <div class="content" v-html="state.training.info">
               
            </div>
        </div>
        <div class="safe-bottom-height"></div>
        <div class="operations">
            <div class="price">￥{{state.training.price}}</div>
			<div class="date">报名时间：<br>{{ state.training.start_regtime }}~{{ state.training.end_regtime }}</div>
            <button class="btn-book" @click="bm">立刻报名</button>
        </div>
    </div>
	<van-popup v-model:show="state.showbm" label-align="left" :style="{ width: '70%' }">
		<van-form @submit="tsubmit">
		  <van-field
		    v-model="state.name"
		    name="name"
		    label="姓名"
		    placeholder="输入姓名"
		    :rules="[{ required: true, message: '请填写姓名' }]"
		  />
		  <van-field
		    v-model="state.phone"
		    type="phone"
		    name="phone"
		    label="手机号"
		    placeholder="输入手机号"
		    :rules="[{ required: true, message: '请填写手机号' }]"
		  />
		  <div style="margin: 16px;">
		    <van-button block native-type="submit" color="#fecd01" size="small" style="margin-top: 16px;color: #000;">
		        报名
		    </van-button>
		  </div>
		</van-form>
	</van-popup>
</template>

<script>
import { onMounted } from 'vue'
import { Image,Popup,Form,Field,Button,Toast } from 'vant'
import { detail,tr_submit } from '@/service/training'
import { reactive, ref } from '@vue/reactivity'
import { userinfo } from '@/service/user'
import { useRoute } from 'vue-router'
export default {
	components: {
		[Popup.name]: Popup,
		[Form.name]: Form,
		[Field.name]: Field,
		[Button.name]: Button,
	},
	setup() {
		const route = useRoute()
		const state = reactive({
		  showbm: false,
		  training: {},
		  name:'',
		  phone:'',
		  tid:0
		})
		onMounted(async () => {
		  const { tid } = route.query
		  state.tid = tid
		  const { data } = await detail('?tid='+tid)
		  state.training = data
		  console.log(state.training)
		})
		async function bm() {
			let userinfos = await userinfo();
			this.state.name = userinfos.data.user.nickname
			this.state.phone = userinfos.data.user.phone
		    this.state.showbm = true;
		}
		async function tsubmit(values) {
			
			values.tid = state.tid
			console.log(values)
			await tr_submit(values)
			Toast.success('报名成功，等待工作人员联系');
			state.showbm = false;
		}
		return {
		    bm,
			state,
			tsubmit
		}
	}
}
</script>

<style lang="scss" scoped>
.banner {
    height: 280px;

    img {
        width: 100%;
        height: 100%;
    }
}

.wrapper {
    background-color: #fff;

    .title {
        padding: 40px 30px;
        font-size: 34px;
        color: #4a90e2;
        text-align: center;
    }

    .content {
        padding: 0 48px;
        font-size: 28px;
        color: #4a4a4a;
        line-height: 180%;
    }

    .consulting-phone {
        padding: 30px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        color: #4a4a4a;
        background-color: #fff;
    }
}

.operations {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 40px;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;

    .price {
        flex: 1;
        font-size: 30px;
        font-weight: 700;
        color: #d0021b;
    }
	.date {
		flex: 2;
		font-size: 20px;
		font-weight: 700;
		color: #a3a2a4;
	}

    .btn-book {
        flex: 0 0 auto;
        width: 200px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 28px;
        color: #000;
        background-color: #fecd01;
        border: none;
        outline: none;
    }
}

.safe-bottom-height {
    height: 160px;
}
</style>