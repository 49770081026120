<template>
    <div class="plan-item" style="position: relative">
		<wx-open-launch-weapp id="launch-btn" :username="shop.username" :path="shop.url" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10000;">
			<div v-is="'script'" type="text/wxtag-template">
				<div style="width: 100%; height: 76px; opacity:0;">跳转小程序</div>
			</div>
		</wx-open-launch-weapp>
        <van-image class="cover" :src="shop.logo" fit="cover" />
        <div class="content">
            <div class="name">{{ shop.title }}</div>
            <div class="summary">￥{{ shop.price }}</div>
        </div>
    </div>
	
	
</template>

<script>
import { Image } from 'vant'
import { reactive } from '@vue/reactivity'
export default {
    components: {
        [Image.name]: Image
    },
    props: {
        shop: Object
    },
    setup(props) {
        const shop = reactive(props.shop)
		function href() {
			window.location.href = this.shop.url
		}
        return {
            shop,
			href
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-item {
    margin: 10px 27px 20px;
    padding: 20px 30px 5px;
    background-color: #fff;
	width: 200px;
	display: inline-table;
    .cover {
        width: 220px;
        height: 220px;
    }

    .content {

        .name {
            font-size: 26px;
            color: #1d1d1d;
			height: 54px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
        }

        .summary {
            
            font-size: 26px;
            color: red;
            line-height: 170%;
        }
    }
}
</style>