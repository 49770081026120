import axios from '../utils/axios'

export function researchlist(params) {//列表
  return axios.post('/Research/researchlist', params);
}
export function getcitylist(params) {//获取城市
  return axios.post('/Research/getcitylist', params);
}
export function detail(params) {//详情
  return axios.get('/Research/detail'+params);
}
export function reserve_submit(params) {//获取城市
  return axios.post('/Research/reserve_submit', params);
}
