import axios from '../utils/axios'

export function getverificationcode(params) {//验证码
  return axios.post('/Identity/verificationcode', params);
}
export function reg(params) {//注册
  return axios.post('/Identity/reg', params);
}
export function userlogin(params) {//登陆
  return axios.post('/Identity/login', params);
}
