<template>
	<div class="search-box">
	    <input type="text" placeholder="查找比赛" v-model="state.keyword" />
	    <button class="btn-search" @click="search()">
	        <img src="@/assets/images/icon-search.png" />
	    </button>
	</div>
    <div class="radar">
        <div class="nearly-over" v-if="state.enddatas.length">
            <div class="subject">
                <van-icon name="clock-o" />
                &nbsp;即将结束
            </div>
            <div class="nearly-over-body" @click="$router.push('radar-show?rid='+endradar.id)" v-for="endradar in state.enddatas" >
                <van-image :src="endradar.logo" />
                <div class="content">
                    <div class="title">{{ endradar.title }}</div>
                    <div class="metas">
                        <div>参赛对象：{{ endradar.participants }}</div>
                        <div>比赛时间：{{ endradar.start_comtime }}~{{ endradar.end_comtime }}</div>
                    </div>
                    <div class="tips">
                        距比赛结束还有
                        <div class="day">{{ endradar.syday }}</div>
                        天
                    </div>
                </div>
            </div>
			
        </div>

        <div class="radar-list">
            <div class="subject">
                <van-image :src="require('@/assets/images/icon-news-1.png')" />
                <div class="title">竞赛雷达</div>
            </div>
			<van-list
			  v-model:loading="state.loading"
			  :finished="state.finished"
			  :finished-text="state.finished_text"
			  @load="onLoad"
			>
				<RadarItem v-for="radar in state.radars" :key="radar.id" :radar="radar" />
			</van-list>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { reactive, toRefs } from 'vue'
import RadarItem from '@/components/RadarItem'
import { radarlist } from '@/service/radar'
import { Icon, Image,List } from 'vant'
import { useRoute, useRouter } from 'vue-router'

export default {
    components: {
        RadarItem,
        [Icon.name]: Icon,
        [Image.name]: Image,
		[List.name]: List
    },
    setup() {
		const route = useRoute()
		const router = useRouter()
		const state = reactive({
		  enddatas: [],
		  radars: [],
		  loading: false,
		  finished: false,
		  finished_text: '',
		  totalPage: 0,
		  page: 1,
		  keyword:''
		})
		onMounted(async () => {
			const { keyword } = route.query
			state.keyword = keyword
		})
		const onLoad = async () => {
			getdata()
		}
		
		async function getdata() {
			let datalist  = await radarlist({ keyword:state.keyword,page: state.page })
			
			if(state.page == 1) {
				state.enddatas = datalist.data.enddatas
				if (datalist.data.datalist.length == 0) {
					state.finished_text = '暂无数据'
				}
			} else {
				state.finished_text = '没有更多了'
			}
			state.radars = state.radars.concat(datalist.data.datalist)
			state.loading = false;
			if (datalist.data.datalist.length < 10) {
				state.finished = true
			}
			state.page = state.page + 1
		}
		
		function search() {
			state.finished_text = ''
			state.radars = []
			state.enddatas = []
			state.page = 1
			state.finished = false
			
		}
        return {
            state,
			onLoad,
			search,
			getdata
        }
    }
}
</script>

<style lang="scss" scoped>
.radar {
    padding-bottom: 10px;

    .nearly-over {
        margin-top: 10px;
        padding: 30px;
        background-color: #fff;

        .subject {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 60px;
            font-size: 28px;
            color: #fff;
            background-image: linear-gradient(to top, #f34f5e 0%, #a1051d 100%);

            .van-icon {
                font-size: 36px;
            }
        }

        .nearly-over-body {
            display: flex;
            margin-top: 36px;

            .van-image {
                width: 236px;
                height: 150px;
            }

            .content {
                margin-left: 20px;

                .title {
                    font-size: 30px;
                    color: #4a90e2;
                }

                .metas {
                    margin-top: 18px;
                    font-size: 24px;
                    color: #4a4a4a;
                }

                .tips {
                    margin-top: 18px;
                    font-size: 26px;

                    .day {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        background-color: #d0021b;
                        font-weight: bold;
                        color: #fff;
                    }
                }
            }
        }
    }

    .radar-list {
        margin-top: 10px;
        padding: 20px 40px;
        background-color: #fff;

        .subject {
            display: flex;
            align-items: center;
            padding: 32px 0;

            .van-image {
                width: 40px;
            }

            .title {
                margin: 0;
                margin-left: 18px;
                color: #4a4a4a;
                font-size: 32px;
                font-weight: bold;
            }
        }
    }
}
</style>