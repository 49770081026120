import axios from '../utils/axios'

export function radarlist(params) {//竞赛列表
  return axios.post('/Radar/list', params);
}
export function infolist(params) {//信息列表
  return axios.post('/Radar/listinfo', params);
}

export function radardetail(getparams) {//竞赛列表
  return axios.get('/Radar/detail'+getparams);
}


