<template>
	<div class="search-box">
	    <input type="text" placeholder="查找培训" v-model="state.keyword" />
	    <button class="btn-search" @click="search()">
	        <img src="@/assets/images/icon-search.png" />
	    </button>
	</div>
    <div class="training">
        <div class="banner" v-if="state.images.length">
            <van-swipe class="my-swipe" :autoplay="3000" height="140">
              <van-swipe-item v-for="image in state.images" :key="image.id" @click="href(image.url)">
                <img class="my-swipe-img" :src="image.img" />
              </van-swipe-item>
            </van-swipe>
        </div>

        <div class="training-list">
            <div class="subject">
                <van-image :src="require('@/assets/images/icon-training.png')" />
                <div class="title">培训报名</div>
            </div>
			<van-list
			  v-model:loading="state.loading"
			  :finished="state.finished"
			  :finished-text="state.page>1 ? 没有更多了 : ''"
			  @load="onLoad"
			>
            <TrainingItem v-for="training in state.trainings" :key="training.id" :training="training" />
			</van-list>
        </div>
    </div>
</template>

<script>
import TrainingItem from '@/components/TrainingItem'
import { Icon, Image,List } from 'vant'
import { traininglist } from '@/service/training'
import { reactive } from '@vue/reactivity'

export default {
    components: {
        TrainingItem,
        [Icon.name]: Icon,
        [Image.name]: Image,
		[List.name]: List
    },
    setup() {
		const state = reactive({
		  images:[],
		  trainings: [],
		  loading: false,
		  finished: false,
		  page: 1,
		  keyword:''
		})
		const onLoad = async () => {
			let datalist  = await traininglist({ keyword: state.keyword,page: state.page })
			state.images = datalist.data.carousel;
			state.trainings = state.trainings.concat(datalist.data.datalist)
			state.loading = false;
			if (datalist.data.datalist.length < 10) state.finished = true
			state.page = state.page + 1
		}
		function search() {
			state.finished = false
			state.loading = true
			state.page = 1
			state.trainings = [];
			onLoad()
		}
		function href(url) {
		    if (url) {
		        window.location.href = url
		    }
		}

        return {
            state,
			onLoad,
			href,
			search
        }
    }
}
</script>

<style lang="scss" scoped>
.training {
    padding-bottom: 10px;

    .banner {
        height: 280px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .training-list {
        margin-top: 10px;
        padding: 20px 40px;
        background-color: #fff;

        .subject {
            display: flex;
            align-items: center;
            padding: 32px 0;

            .van-image {
                width: 40px;
            }

            .title {
                margin: 0;
                margin-left: 18px;
                color: #4a4a4a;
                font-size: 32px;
                font-weight: bold;
            }
        }
    }
}
</style>