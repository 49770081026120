<template>
    <div class="plan-item">
        <van-image class="cover" :src="plan.logo" fit="cover" />
        <div class="content">
            <div class="name">{{ plan.name }}</div>
            <div class="summary">{{ plan.content }}</div>
        </div>
    </div>
</template>

<script>
import { Image } from 'vant'
import { reactive } from '@vue/reactivity'
export default {
    components: {
        [Image.name]: Image
    },
    props: {
        plan: Object
    },
    setup(props) {
        const plan = reactive(props.plan)

        return {
            plan
        }
    }
}
</script>

<style lang="scss" scoped>
.plan-item {
    display: flex;
    margin-top: 16px;
    padding: 30px;
    background-color: #fff;
    border-radius: 6px;

    .cover {
        flex: 0 0 auto;
        width: 265px;
        height: 178px;
    }

    .content {
        margin-left: 30px;

        .name {
            font-size: 30px;
            color: #1d1d1d;
        }

        .summary {
            margin-top: 18px;
            font-size: 20px;
            color: #8f8f8f;
            line-height: 170%;
        }
    }
}
</style>