<template>
    <div class="login">
        <div class="header">
			<div class="header-inner">
				<div class="left" @click="$router.push('/')">
					<van-icon name="arrow-left" />
				</div>
				<div class="title">科学少年社</div>
				<div class="right"></div>
			</div>
        </div>
        <div class="auth-box">
            <div class="tabs">
                <router-link to="/login" class="tab active">登录</router-link>
                <router-link to="/register" class="tab">注册</router-link>
            </div>
            <div class="auth">
                <van-form @submit="login">
                    <van-field
                        v-model="user.phone"
                        name="phone"
                        label="手机号"
                        label-width="60"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                    />
                    <van-field
                        v-model="user.password"
                        type="password"
                        name="password"
                        label="密码"
                        label-width="60"
                        placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                    />
                    <van-button block size="small" native-type="submit" color="#E26EFA" style="margin-top: 20px;font-size: 18px;padding: 18px;">
                        登录
                    </van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { reactive } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { userlogin } from '@/service/identity'
import md5 from 'js-md5'
import { setLocal } from '@/common/js/utils'
import { Icon,Toast, Form, Field, CellGroup, Button } from 'vant'
export default {
    components: {
		[Icon.name]: Icon,
        [Toast.name]: Toast,
        [Button.name]: Button,
        [Form.name]: Form,
        [Field.name]: Field,
        [CellGroup.name]: CellGroup
    },
    setup() {
        const user = reactive({
            phone: null,
            password: null
        })
        const router = useRouter()
		onMounted(async () => {
		  setLocal('token', '')
		})
        async function login(values) {
			Toast.loading({
			  message: '登陆中...',
			  forbidClick: true
			});
			const { data } = await userlogin({
				"phone": values.phone,
				"password": md5(values.password)
			})
			setLocal('token', data.token)
			setTimeout(()=>{
					
					if(data.nickname != '') {
						window.location.href = '/'
					} else {
						window.location.href = 'http://h5.kxcbzx.com//#/profile'
					}
					Toast.clear()
			    },1000)
			
		}
        return {
            user,
            login
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    min-height: 100vh;
    background-color: #ede2f7;
    .header {
        width: 100%;
        height: 354px;
        background: url('../assets/images/bg-user-header.png') center no-repeat;
        background-size: 100% 100%;

        .header-inner {
        	display: flex;
        	align-items: center;
        
        	.left,
        	.right {
        		width: 100px;
        		height: 100px;
        		line-height: 100px;
        		text-align: center;
        		font-size: 44px;
        	}
        
        	.title {
        		width: 100%;
        		height: 100px;
        		text-align: center;
        		line-height: 100px;
        		font-size: 36px;
        		color: #000;
        	}
        }
    }
	.van-cell {
		line-height: 100px;
	}

    .auth-box {
        margin-top: -140px;

        .tabs {
            width: 500px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0 auto;

            & > .tab {
                flex: 1;
                height: 80px;
                text-align: center;
                font-size: 36px;
                color: #fff;

                &.active {
                    position: relative;
                    color: #fff400;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        width: 110px;
                        height: 6px;
                        margin-left: -55px;
                        background: #fff400;
                    }
                }
            }
        }

        .auth {
            width: 680px;
            margin: 0 auto;
            padding: 30px;
            border-radius: 5px;
            background-color: #fff;
            box-sizing: border-box;
            box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.1);
        }
    }
}
</style>