<template>

	<div class="search-box">
		<input type="text" placeholder="查找比赛" v-model="state.keyword" />
		<button class="btn-search" @click="search()">
			<img src="@/assets/images/icon-search.png" />
		</button>
	</div>
	<div class="banner" v-if="state.images.length">
		<van-swipe class="my-swipe" :autoplay="3000" height="140">
			<van-swipe-item v-for="image in state.images" :key="image.id" @click="href(image.url)">
				<img class="my-swipe-img" :src="image.img" />
			</van-swipe-item>
		</van-swipe>
	</div>

	<div class="operations block">
		<van-grid :column-num="4" :border="false">
			<div style="flex-basis: 25%;position: relative" v-for="value in operations" :key="value.alias">
				<div v-if="value.alias=='shop'">
					<wx-open-launch-weapp id="launch-btn" :username="state.xapp.username" :path="state.xapp.path"
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10000;">
						<div v-is="'script'" type="text/wxtag-template">
							<div style="width: 100%; height: 76px; opacity:0;">跳转小程序</div>
						</div>
					</wx-open-launch-weapp>
				</div>
				<div v-else-if="value.alias=='plan'">
					<wx-open-launch-weapp id="launch-btn" :username="state.xapp.username2" :path="state.xapp.path2"
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10000;">
						<div v-is="'script'" type="text/wxtag-template">
							<div style="width: 100%; height: 76px; opacity:0;">跳转小程序</div>
						</div>
					</wx-open-launch-weapp>
				</div>
				<van-grid-item :icon="require(`@/assets/images/icon-${value.alias}.png`)" :text="value.name" @click="jump(value.alias)" />
			</div>

		</van-grid>
	</div>

	<div class="block">
		<div class="block-header">
			<div class="subject">
				<van-image :src="require('@/assets/images/icon-news.png')" />
				<div class="title">信息发布</div>
			</div>
			<div class="more" @click="$router.push('/info')">更多 ></div>
		</div>
		<RadarItem v-for="radar in state.radars" :key="radar.id" :radar="radar" />
	</div>

	<div class="test block">
		<div class="block-header">
			<div class="subject">
				<van-image :src="require('@/assets/images/icon-test.png')" />
				<div class="title">综合测试</div>
			</div>
		</div>
		<div class="block-body">
			<van-image :src="require('@/assets/images/img-test-1.png')" @click="href('http://szpj.kxcbzx.com/index.php/exam/login/index')"/>
			<van-image :src="require('@/assets/images/img-test-2.png')" @click="href('http://dati2.kxcbzx.com/index.php?ac=login')"/>
			<van-image :src="require('@/assets/images/img-test-3.png')" @click="href('http://xlcs.kxcbzx.com/static/wenjuan/index.html#/loginFor24')"/>
			<van-image :src="require('@/assets/images/img-test-4.png')" @click="href('http://xlcs.kxcbzx.com/static/wenjuan/index.html#/login')"/>
		</div>
	</div>

	<div class="block">
		<div class="block-header">
			<div class="subject">
				<van-image :src="require('@/assets/images/icon-science-subject.jpg')" />
				<div class="title">科普研学</div>
			</div>
			<div class="more" @click="$router.push('/science')">更多 ></div>
		</div>
		<ScienceItem v-for="science in state.sciences" :key="science.id" :science="science" />
	</div>

	<div class="block">
		<div class="block-header">
			<div class="subject">
				<van-image :src="require('@/assets/images/icon-cart.png')" />
				<div class="title">科学好物</div>
			</div>
			<div class="more" @click="$router.push('/shop')">更多 ></div>
		</div>
		<ShopItem v-for="shop in state.shop" :key="shop.id" :shop="shop" />
	</div>
</template>

<script>
	import {
		onMounted
	} from 'vue'
	import RadarItem from '@/components/RadarItem'
	import ScienceItem from '@/components/ScienceItem'
	import ShopItem from '@/components/ShopItem'
	import {
		getHome,
		getWx
	} from '@/service/home'
	import {
		Grid,
		GridItem,
		Image,
		Swipe,
		SwipeItem,
		Toast
	} from 'vant'
	import {
		reactive
	} from '@vue/reactivity'
	import {
		useRouter
	} from 'vue-router'
	import wx from 'weixin-js-sdk';

	export default {
		components: {
			RadarItem,
			ScienceItem,
			ShopItem,
			[Image.name]: Image,
			[Grid.name]: Grid,
			[GridItem.name]: GridItem,
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			[Toast.name]: Toast,
		},
		setup() {
			const operations = reactive([{
					name: '竞赛雷达',
					alias: 'radar'
				},
				{
					name: '成长档案',
					alias: 'archive'
				},
				{
					name: '学业规划',
					alias: 'plan'
				},
				{
					name: '综合测试',
					alias: 'test'
				},
				{
					name: '科普研学',
					alias: 'science'
				},
				{
					name: '培训报名',
					alias: 'training'
				},
				// {
				//     name: '会员商店',
				//     alias: 'shop'
				// },
				
				{
					name: '我的账户',
					alias: 'member'
				},
				{
				     name: '科学好物',
				     alias: 'shop'
				},
			])
			const state = reactive({
				radars: [],
				sciences: [],
				shop: [],
				images: [],
				keyword: '',
				xapp: []
			})
			const router = useRouter()

			onMounted(async () => {
				const {
					data
				} = await getHome()
				state.images = data.carousel;
				state.radars = data.competition_radar;
				state.sciences = data.research;
				state.shop = data.shop;
				state.xapp = data.xapp;
		 	wxconfig();
			})

			function jump(alias) {
				if (alias === 'shop' || alias === 'plan') {
					return
				}

				router.push(alias)
			}

			function search(val) {

				router.push('radar?keyword=' + state.keyword)
			}

			function href(url) {
				if (url) {
					window.location.href = url
				}
			}
			async function wxconfig() {
				const {
					data
				} = await getWx({
					url: location.href.split('#')[0]
				})
				wx.config({
					debug: false,
					appId: 'wx09a9523f07e1d16a',
					timestamp: data.timestamp,
					nonceStr: data.nonceStr,
					signature: data.signature,
					jsApiList: ['wx-open-launch-weapp'],
					openTagList: ['wx-open-launch-weapp']
				});

				wx.ready(function() {
		 		//自动执行的

					//alert('成功');
				});

				wx.error(function(res) {
					//alert(res.errMsg);
				});
			}

			return {
				state,
				operations,
				jump,
				href,
				search,
				wxconfig
			}
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		height: 280px;

		.my-swipe .van-swipe-item {
			color: #fff;
			font-size: 20px;
			line-height: 280px;
			height: 280px;
			text-align: center;
		}

		.my-swipe .my-swipe-img {
			width: 100%;
			height: 280px;
		}
	}

	.block {
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 20px 40px;
		background-color: #fff;

		.block-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 32px 0;

			.subject {
				display: flex;
				align-items: center;

				.van-image {
					width: 40px;
				}

				.title {
					margin: 0;
					margin-left: 18px;
					color: #4a4a4a;
					font-size: 32px;
					font-weight: bold;
				}
			}

			.more {
				font-size: 26px;
				color: #999;
			}
		}
	}

	.test {
		.block-body {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;
			flex-wrap: wrap;

			.van-image {
				width: 320px;
				margin-bottom: 20px;
			}
		}
	}
</style>
