import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'
axios.defaults.baseURL = 'http://h5admin.kxcbzx.com/api'
axios.defaults.headers['Authorization'] = localStorage.getItem('token') ? 'bearer '+localStorage.getItem('token') : ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail('服务端异常！')
    return Promise.reject(res)
  }
  if (res.data.code != 200) {
    
    if (res.data.code == 1001) {
      router.push({ path: '/login' })
    } else {
		if (res.data.msg) Toast.fail(res.data.msg)
	}
    return Promise.reject(res.data)
  }

  return res.data
})

export default axios
