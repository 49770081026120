<template>
    <div class="test">
        <div class="subject">
            <van-image :src="require('@/assets/images/icon-test.png')" />
            <div class="title">综合测试</div>
        </div>
        <div class="test-list">
            <van-image :src="require('@/assets/images/img-test-1.png')" @click="href('http://szpj.kxcbzx.com/index.php/exam/login/index')"/>
            <van-image :src="require('@/assets/images/img-test-2.png')" @click="href('http://dati2.kxcbzx.com/index.php?ac=login')"/>
			<van-image :src="require('@/assets/images/img-test-3.png')" @click="href('http://xlcs.kxcbzx.com/static/wenjuan/index.html#/loginFor24')"/>
			<van-image :src="require('@/assets/images/img-test-4.png')" @click="href('http://xlcs.kxcbzx.com/static/wenjuan/index.html#/login')"/>
        </div>
    </div>
</template>

<script>
import { Image } from 'vant'
export default {
    components: {
        [Image.name]: Image
    },
	setup() {
		function href(url) {
			window.location.href = url
		}
	
	    return {
			href
	    }
	}
}
</script>

<style lang="scss" scoped>
.test {
    padding: 60px 40px;
    background-color: #fff;

    .subject {
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        border-bottom: 1px solid #f1f1f1;

        .van-image {
            width: 40px;
        }

        .title {
            margin: 0;
            margin-left: 18px;
            color: #4a4a4a;
            font-size: 32px;
            font-weight: bold;
        }
    }

    .test-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
		flex-wrap: wrap;

        .van-image {
            width: 320px;
			margin-bottom: 20px;
        }
    }
}
</style>