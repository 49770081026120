<template>
    <div class="safe-bottom-height"></div>

    <van-tabbar v-model="active" active-color="#4a4a4a" inactive-color="#4a4a4a" safe-area-inset-bottom route>
        <van-tabbar-item replace to="/">
            <span>首页</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? require('@/assets/images/icon-tab-home-active.png')
                            : require('@/assets/images/icon-tab-home.png')
                    "
                />
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/radar">
            <span>竞赛雷达</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? require('@/assets/images/icon-tab-radar-active.png')
                            : require('@/assets/images/icon-tab-radar.png')
                    "
                />
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/archive">
            <span>成长档案</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? require('@/assets/images/icon-tab-archive-active.png')
                            : require('@/assets/images/icon-tab-archive.png')
                    "
                />
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/member">
            <span>我的账户</span>
            <template #icon="props">
                <img
                    :src="
                        props.active
                            ? require('@/assets/images/icon-tab-member-active.png')
                            : require('@/assets/images/icon-tab-member.png')
                    "
                />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import { ref } from '@vue/reactivity'
export default {
    components: {
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem
    },
    setup() {
        const active = ref(0)

        return { active }
    }
}
</script>

<style lang="scss" scoped>
.safe-bottom-height {
    height: 100px;
}
</style>