import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Register from '@/views/Register'
import ForgetPassword from '@/views/ForgetPassword'
import Login from '@/views/Login'
import Profile from '@/views/Profile'
import Radar from '@/views/Radar'
import Info from '@/views/Info'
import RadarShow from '@/views/RadarShow'
import Training from '@/views/Training'
import TrainingShow from '@/views/TrainingShow'
import Science from '@/views/Science'
import ScienceShow from '@/views/ScienceShow'
import Test from '@/views/Test'
import Shop from '@/views/Shop'
import Plan from '@/views/Plan'
import Member from '@/views/Member'
import CertificateVerification from '@/views/CertificateVerification'
import Archive from '@/views/Archive'
import ArchiveList from '@/views/ArchiveList'
import ArchiveAdd from '@/views/ArchiveAdd'
import ArchivePreview from '@/views/ArchivePreview'
import ArchivePrinting from '@/views/ArchivePrinting'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            single: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            single: true
        }
    },
	{
	    path: '/forget-password',
	    name: 'ForgetPassword',
	    component: ForgetPassword,
	    meta: {
	        single: true
	    }
	},
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            single: true
        }
    },
    {
        path: '/radar',
        name: 'Radar',
        component: Radar
    },
	{
	    path: '/info',
	    name: 'Info',
	    component: Info
	},
    {
        path: '/radar-show',
        name: 'RadarShow',
        component: RadarShow,
        meta: {
            noFooter: true
        }
    },
    {
        path: '/training',
        name: 'Training',
        component: Training
    },
    {
        path: '/training-show',
        name: 'TrainingShow',
        component: TrainingShow,
        meta: {
            noFooter: true
        }
    },
    {
        path: '/science',
        name: 'Science',
        component: Science
    },
    {
        path: '/science-show',
        name: 'ScienceShow',
        component: ScienceShow,
        meta: {
            noFooter: true
        }
    },
    {
        path: '/test',
        name: 'Test',
        component: Test
    },
    {
        path: '/plan',
        name: 'Plan',
        component: Plan
    },
	{
	    path: '/shop',
	    name: 'shop',
	    component: Shop
	},
    {
        path: '/certificateverification',
        name: 'CertificateVerification',
        component: CertificateVerification,
        meta: {
            noHeader: true,
			noFooter: true
        }
    },
	{
	    path: '/member',
	    name: 'Member',
	    component: Member,
	    meta: {
	        noHeader: true
	    }
	},
    {
        path: '/archive',
        name: 'Archive',
        component: Archive
    },
    {
        path: '/archive/list',
        name: 'ArchiveList',
        component: ArchiveList
    },
    {
        path: '/archive/add',
        name: 'ArchiveAdd',
        component: ArchiveAdd,
        meta: {
            noFooter: true
        }
    },
    {
        path: '/archive/preview',
        name: 'ArchivePreview',
        component: ArchivePreview,
        meta: {
            noFooter: true
        }
    },
	{
	    path: '/archive/printing',
	    name: 'ArchivePrinting',
	    component: ArchivePrinting,
	    meta: {
	        noFooter: true
	    }
	},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
