import axios from '../utils/axios'

export function userinfo(params) {//完善信息
  return axios.post('/User/userinfo', params);
}

export function profile(params) {//完善信息
  return axios.post('/User/profile', params);
}
export function upavatar(params) {//上传头像
  return axios.post('/User/upavatar', params);
}
export function forgetvcode(params) {//上传头像
  return axios.post('/User/verificationcode', params);
}
export function forget_password(params) {//上传头像
  return axios.post('/User/forget_password', params);
}

