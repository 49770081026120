<template>
    <Header v-if="!$router.currentRoute.value.meta.single && !$router.currentRoute.value.meta.noHeader" />
    <router-view />
    <Footer v-if="!$router.currentRoute.value.meta.single && !$router.currentRoute.value.meta.noFooter" />
</template>

<script>
import Header from '@/views/layouts/Header'
import Footer from '@/views/layouts/Footer'

export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style lang="scss">
#app {
    min-height: 100vh;
    background-color: #eae9e9;
}

a {
    text-decoration: none;
    color: #000;
}
.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    height: 60px;
    background-color: #fff;

    input {
        flex: 1;
        height: 60px;
        padding: 0 28px;
        border: none;
        background-color: #eaeaea;
        border-radius: 16px;
        box-sizing: border-box;
        font-size: 26px;
    }

    .btn-search {
        flex: 0 0 auto;
        width: 60px;
        height: 60px;
        margin-left: 16px;
        padding: 0;
        outline: none;
        background: none;
        border: none;

        img {
            width: 80%;
        }
    }
}
</style>
