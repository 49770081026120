<template>
	<div class="search-box">
	    <input type="text" placeholder="查找课程" v-model="state.keyword" />
	    <button class="btn-search" @click="search()">
	        <img src="@/assets/images/icon-search.png" />
	    </button>
	</div>
    <div class="science" >
        <div class="banner" v-if="state.images.length">
            <van-swipe class="my-swipe" :autoplay="3000" height="140">
              <van-swipe-item v-for="image in state.images" :key="image.id" @click="href(image.url)">
                <img class="my-swipe-img" :src="image.img" />
              </van-swipe-item>
            </van-swipe>
        </div>

        <div class="subject">
            <van-image fit="cover" :src="require('@/assets/images/icon-science-subject.jpg')" />
            <div class="title">科普研学</div>
        </div>
        <div class="tabs">
            <div class="tab" :class="state.active === 1 ? 'active' : ''" @click="onRefresh(1)">
                <van-image fit="contain" :src="require('@/assets/images/img-science-btn-1.png')" />
            </div>
            <div class="tab" :class="state.active === 2 ? 'active' : ''" @click="onRefresh(2)">
                <van-image fit="contain" :src="require('@/assets/images/img-science-btn-2.png')" />
            </div>
        </div>

        <van-tabs v-model:active="state.tabActive" @click="changeTab" line-height="0" title-active-color="#4a90e2" style="margin-top: 5px">
            <van-tab v-for="tab in state.tabList" :title="tab.name" :key="tab.id" title-style="font-weight: bold;">
                <div style="padding: 0 15px; background-color: #fff">
					<van-list
					  v-model:loading="state.loading"
					  :finished="state.finished"
					  :finished-text="state.page>1 ? 没有更多了 : ''"
					  @load="onLoad"
					>
                    <ScienceItem v-for="science in state.sciences" :key="science.id" :science="science" />
					</van-list>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import TrainingItem from '@/components/TrainingItem'
import ScienceItem from '@/components/ScienceItem'
import { Icon,Swipe, SwipeItem, Image, Tabs, Tab,List } from 'vant'
import { researchlist,getcitylist } from '@/service/research'
import { reactive, ref } from '@vue/reactivity'

export default {
    components: {
        TrainingItem,
        ScienceItem,
        [Icon.name]: Icon,
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
        [Image.name]: Image,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
		[List.name]: List
    },
    setup() {

		const state = reactive({
		  images:[],
		  active: 1,
		  tabActive: 0,
		  tabList: [],
		  sciences: [],
		  loading: false,
		  finished: false,
		  refreshing: false,
		  xin:1,
		  page: 1,
		  keyword:''
		})
		onMounted(async () => {
		  const cityname = localStorage.getItem('cityname')
		  //console.log(localStorage.getItem('cityname'))
		  const tabList  = await getcitylist({
			  cityname:cityname
		  })
		  
		  state.tabList = tabList.data.citylist
		  state.tabActive = tabList.data.dw
		  state.images = tabList.data.carousel;
		})
		const init = async () => {
		 const researchlists  = await researchlist({
		 	page: state.page,
		 	type: state.active,
			keyword: state.keyword,
		 	city: state.tabList[state.tabActive]['name']
		 })
		 state.sciences = state.sciences.concat(researchlists.data.datalist)
		 state.loading = false;
		 state.xin = 0;
		 if (researchlists.data.datalist.length < 10) state.finished = true
		 //console.log(researchlists);
		}
		const onLoad = async () => {
			if (!state.refreshing && state.xin == 0 ) {
			  state.page = state.page + 1
			}
			if (state.refreshing) {
			  state.sciences = [];
			  state.refreshing = false;
			}
			init();
			//state.tabList = tabList
		}
		const onRefresh = (type) => {
		  state.refreshing = true
		  state.finished = false
		  state.loading = true
		  if(type) {
			  state.active = type
		  }
		  state.page = 1
		  onLoad()
		}
		function search() {
			onRefresh()
		}
		
		const changeTab = (name) => {
		  onRefresh()
		}
		function href(url) {
		    if (url) {
		        window.location.href = url
		    }
		}

        return {
            state,
			onLoad,
			init,
			onRefresh,
			changeTab,
			href,
			search
        }
    }
}
</script>

<style lang="scss" scoped>
.science {
    padding-bottom: 10px;

    .banner {
        height: 280px;

        .my-swipe .van-swipe-item {
            color: #fff;
            font-size: 20px;
            line-height: 280px;
        	height:280px;
            text-align: center;
        }
        .my-swipe .my-swipe-img {
            width: 100%;
            height: 280px;
        }
    }

    .subject {
        display: flex;
        align-items: center;
        padding: 32px 30px;
        background-color: #fff;

        .van-image {
            width: 40px;
        }

        .title {
            margin: 0;
            margin-left: 18px;
            color: #4a4a4a;
            font-size: 32px;
            font-weight: bold;
        }
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 44px;
        background-color: #fff;

        .tab {
            width: 320px;
            height: 135px;
            text-align: center;
            background-color: #f2f4fe;
            border-radius: 10px;

            &.active {
                position: relative;
                background-color: #c5cef8;

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -44px;
                    margin-left: -30px;
                    border-top: 16px solid #c5cef8;
                    border-right: 30px solid #fff;
                    border-left: 30px solid #fff;
                    border-bottom: 16px solid #fff;
                }
            }

            .van-image {
                width: auto;
                height: 85%;
                vertical-align: middle;
            }
        }
    }
}
</style>